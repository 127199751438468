/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import Vue from 'vue'
import axios from 'axios';
import router from '../router';
import store from '../store/index';
import { ACCESS_TOKEN } from "@/store/mutation-types"
import { Message } from 'element-ui';
// 引入进度条
import nProgress from 'nprogress';
import 'nprogress/nprogress.css'
nProgress.configure({ showSpinner: false });
/** 
 * 提示函数 
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
  Message(msg)
}


/** 
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: '/login',
    query: {
      redirect: router.currentRoute.fullPath
    }
  });
}

/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
      toLogin();
      break;
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      tip('登录过期，请重新登录');
      Vue.ls.remove(ACCESS_TOKEN);
      setTimeout(() => {
        toLogin();
      }, 1000);
      break;
    // 404请求不存在
    case 404:
      tip('请求的资源不存在');
      break;
    case 500:
      router.replace({
        path: '/500',
        query: {
          redirect: router.currentRoute.fullPath
        }
      })
      break;
    default:
      console.log(other);
  }
}
let baseURL = process.env.NODE_ENV == 'development' ? '/' :process.env.VUE_APP_BASE_API
// 创建axios实例
var instance = axios.create({
  baseURL: baseURL,
  timeout: 0
});
// 设置post请求头
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
/** 
 * 请求拦截器 
 * 每次请求前，如果存在token则在请求头中携带token 
 */
instance.interceptors.request.use(
  config => {
    if (config.nprogress !== false){
      nProgress.start()
    }
    var rootRoute = router.currentRoute.matched[0]
    config.headers.identity = config.headers.identity ? config.headers.identity : rootRoute ? rootRoute.meta.identity : null;
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况        
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token        
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码        
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。       
    const token = store.state.user.token || Vue.ls.get(ACCESS_TOKEN);
    token && (config.headers.token = token);
    return config;
  },
  error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  // res => res.status === 200 ? Promise.resolve(res) : Promise.reject(res),
  res => {
    nProgress.done()
    if (res.config.responseType === 'blob') {
      return Promise.resolve(res)
    } else if (res.status === 200) {
      const { data } = res
      if (data.code !== 0 && data.code !== 200) {
        if(!res.config.hideTip){
          tip(data.message);
        }
        if (data.code == 4000) {
          router.replace({
            path: '/certification/company',
            query: {
              redirect: router.currentRoute.fullPath
            }
          });

        } else if (data.code == 4001 || data.code == 4002) {
          router.replace({
            path: '/certification/deposit',
            query: {
              redirect: router.currentRoute.fullPath,
              type: data.code == 4001 ? 1 : 2
            }
          });
        }
      }
      return Promise.resolve(res)
    } else {
      return Promise.reject(res)
    }

  },
  // 请求失败
  error => {
    const { response, config } = error;
   
    if (config.errorHandle){
        let res = config.errorHandle(response);
        if (res instanceof Promise){
          return res
        }
        return Promise.resolve(res);
    }
    if (response) {
      // 请求已发出，但是不在2xx的范围 
      errorHandle(response.status, response.data.message);
      return Promise.reject(response);

    } else {
      return Promise.reject(error);
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      // if (!window.navigator.onLine) {
      //   store.commit('changeNetwork', false);
      // } else {
      //   return Promise.reject(error);
      // }
    }
  });

export default instance;