<template>
  <div class="item-wrap" @click.stop="jumpDetail(item.shopId, item.id)">
    <div class="imgSrc">
      <img :src="item.cover" alt="" />
    </div>
    <div class="goods-amount">
      <div>
        ￥{{
          format_with_array((item.minPrice && item.minPrice.toFixed(2)) || "-")
        }}
        ~
        {{
          format_with_array((item.maxPrice && item.maxPrice.toFixed(2)) || "-")
        }}
      </div>
    </div>
    <div class="goods-bottom">
      <div class="goods-transway">
        <div class="tax">
          <el-popover placement="bottom" trigger="hover" content="含税">
            <img
              v-if="item.taxIncluded"
              slot="reference"
              src="~@/assets/images/home/taxIncluded.png"
              alt=""
            />
          </el-popover>
          <el-popover placement="bottom" trigger="hover" content="不含税">
            <img
              v-if="!item.taxIncluded"
              slot="reference"
              src="~@/assets/images/home/untaxIncluded.png"
              alt=""
            />
          </el-popover>
          <el-popover placement="bottom" trigger="hover" content="含运费">
            <img
              v-if="item.includeTransFee"
              slot="reference"
              src="~@/assets/images/home/includeTransFee.png"
              alt=""
            />
          </el-popover>
          <el-popover placement="bottom" trigger="hover" content="含装卸费">
            <img
              v-if="item.includeLoadUnload"
              slot="reference"
              src="~@/assets/images/home/includeLoad.png"
              alt=""
            />
          </el-popover>
        </div>
        <div v-for="it in item.transportWay" :key="it">
          <template v-if="it == 1">
            <el-popover placement="bottom" trigger="hover" content="自提">
              <img
                slot="reference"
                src="~@/assets/images/home/shipper.png"
                alt=""
              />
            </el-popover>
          </template>
          <template v-if="it == 2">
            <el-popover placement="bottom" trigger="hover" content="配送">
              <img
                slot="reference"
                src="~@/assets/images/home/delivery.png"
                alt=""
              />
            </el-popover>
          </template>
        </div>
      </div>
    </div>
    <div class="goods-introduce">
      {{ item.name || "_" }}
    </div>
    <div class="goods-brand">品牌：{{ item.brand || "-" }}</div>
    <div class="goods-supplier">供应商：{{ item.supplier || "-" }}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    jumpDetail(id, goodsId) {
      if (!goodsId) return false;
      this.$router.push({
        name: "goodsDetails",
        params: {
          goodsId: goodsId
        },
        query:{
          broadHead:this.broadHead,
          isSku:1
        }
      });
    },
    format_with_array(number) {
      // 转为字符串，并按照.拆分
      const arr = (number + "").split(".");
      // 整数部分再拆分
      const int = arr[0].split("");
      // 小数部分
      const fraction = arr[1] || "";
      // 返回的变量
      let r = "";
      int.reverse().forEach(function (v, i) {
        // 非第一位并且是位值是3的倍数，添加“,”
        if (i !== 0 && i % 3 === 0) {
          r = v + "," + r;
        } else {
          // 正常添加字符(这是好写法)
          r = v + r;
        }
      });
      // 整数部分和小数部分拼接
      return r + (fraction ? "." + fraction : "");
    },
  }
};
</script>

<style lang="scss" scoped>
.item-wrap {
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  width: 232px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  cursor: pointer;
  .imgSrc {
    width: 212px;
    height: 120px;
    border: 1px solid #f0f0f0;
    margin-bottom: 21px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .goods-amount {
    display: flex;
    justify-content: space-between;
    color: #e60012;
    font-size: 20px;
    margin-bottom: 8px;
  }
  .tax {
    img {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }
  }
  .goods-introduce {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 600;
    color: #333333;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 设置最大显示行数 */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    margin-bottom: 13px;
  }
  .goods-brand {
    color: #999999;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 11px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .goods-standards {
    color: #999999;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 11px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .goods-supplier {
    color: #999999;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 11px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .goods-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .goods-transway {
      display: flex;
      img {
        height: 20px;
        width: 20px;
        margin-right: 11px;
      }
    }
    .addCar {
      display: flex;
      align-items: center;
      .car {
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f6f6f6;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
    }
  }
}
.item-wrap:nth-of-type(5n) {
  margin-right: 0;
}
</style>
