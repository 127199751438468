import Vue from "vue"
import store from "@/store"
import { ACCESS_TOKEN } from "@/store/mutation-types"

export function getToken() {
  return Vue.ls.get(ACCESS_TOKEN)
}

export function setToken(token) {
  return Vue.ls.set(ACCESS_TOKEN, token, 7 * 24 * 60 * 60 * 1000)
}

export function removeToken() {
  return Vue.ls.remove(ACCESS_TOKEN)
}

export function getUserInfo() {
  return store.getters["user/userInfo"];
}