import axios from '@/utils/http'
import config from '@/config/index'

/**
 * 商品分类树
 * @param {*} params 
 * @returns 
 */
export function getGoodsCategoryTree(params) {
  return axios({
    url: `${config.baseUrl}/goods/category/tree`,
    method: 'get',
    params
  })
}
/**
 * 商品分类树（包含大类）
 */
export function allGoodsCategoryTree(){
  return axios({
    url:`${config.baseUrl}/goods/category/all/tree`,
    method:'get'
  })
}