

export default {
  checkPhone: (rule, value, callback) => {
    let phoneRegex = /(?:0|86|\+86)?1[3-9]\d{9}/;
    if (phoneRegex.test(value)) {
      callback()
    } else {
      callback('请输入正确的手机号')
    }
  },
  // 网址校验
  checkWeb: (rule, value, callback) => {
    let urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))?(?::\d{2,5})?(?:[/?#]\S*)?$/i;

    if (urlRegex.test(value)) {
      callback()
    } else {
      callback('请输入正确的网址')
    }
  },
  checkInteger: (rule, value, callback) => {
    let reg = /^[1-9]\d*$/
    if(!value){
      return callback()
    }
    if(reg.test(value)){
      callback()
    } else {
      callback('请输入正整数')
    }
  },
  checkIdCard: (rule, value, callback) => {
    let reg = /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/
    if(reg.test(value)){
      callback()
    } else {
      callback('请输入正确的身份证号')
    }
  },
  // 车牌号校验
  checkCarCard: (rule, value, callback)=>{
    let reg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/
    if(reg.test(value)){
      callback()
    } else {
      callback('请输入正确的车牌号如：冀A00000')
    }
  },
  // 邮箱验证
  checkEmail: (rule, value, callback) => {
    let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if(reg.test(value)){
      callback()
    } else {
      callback('请输入正确的邮箱')
    }
  },
  checkPersonalName: (rule, value, callback) => {
    let reg = /^(?:[\u4e00-\u9fa5·]{2,16})$/
    // let enReg = /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/
    if (reg.test(value)){
      callback()
    }else{
      callback('请重新输入')
    }
  },
  checkPassword: (rule, value, callback) => {
    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={}\\[\]|\\:;"'<>,.?/])[A-Za-z\d~`!@#$%^&*()_\-+={}\\[\]|\\:;"'<>,.?/]{8,}$/
    if (reg.test(value)){
      callback()
    }else{
      callback('密码至少8位需包含大小写特殊字符')
    }
  },
  checkSmsCode: (rule, value, callback) => {
    let reg = /^\d{6}$/
    if (reg.test(value)){
      callback()
    }else{
      callback('验证码格式错误')
    }
  },
  // 正则校验只能输入正整数
  checkNumber: (rule, value, callback) => {
    let reg = /^[0-9]*$/
    if(reg.test(value)){
      callback()
    } else {
      callback('这不是合法的数字')
    }
  },
  // 正则校验正数包含小数
  checkPositiveNumber: (rule, value, callback) => {
    let reg = /^[0-9]+(.[0-9]+)?$/
    if(reg.test(value)&&value>0){
      callback()
    } else {
      callback('请输入正数')
    }
  }

}