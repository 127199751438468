export default [
  {
    path: '/certification',
    redirect: '/certification/company',
    component: () => import('@/views/certification/IndexView.vue'),
    children: [
      {
        path: 'company',
        name: 'CompanyCert',
        component: () => import('@/views/certification/company/CompanyCert.vue')
      },
      {
        path: 'deposit',
        name: 'Deposit',
        component: () => import('@/views/certification/Deposit.vue')
      }
    ]
  },
  {
    path: '/onlineLinkPage',
    name: 'onlineLinkPage',
    component: () => import('@/views/certification/company/onlineLinkPage.vue'),
    meta: {
      isShowHeader: true,
    }
  }
]