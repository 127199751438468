
import axios from '@/utils/http'
import config from '@/config/index'


// export function Login(data) {
//   return axios({
//     url: `${config.baseUrl}/account/login/mobile`,
//     method: 'post',
//     data
//   })
// }
// //登录（账号密码）
// export function loginByAccount({account,password}) {
//   return axios({
//     url: `${config.baseUrl}/account/login/account`,
//     method: 'post',
//     data: {account,password}
//   })
// }
//登录(账号登录/手机号登录)
export function login(url,data){
  return axios({
    url: `${config.baseUrl}/account/login/` + url ,
    method: 'post',
    data
  })
 }
export function getInfo() {
  return axios({
    url: `${config.baseUrl}/account/info`,
    method: 'get'
  })
}
export function logout() {
  return axios({
    url: `${config.baseUrl}/account/logout`,
    method: 'post'
  })
}

export function RegistryByMobile(data) {
  return axios({
    url: `${config.baseUrl}/account/registry/mobile`,
    method: 'post',
    data
  })
}

export function RetrievePassword(data) {
  return axios({
    url: `${config.baseUrl}/account/password/retrieve`,
    method: 'post',
    data
  })
}

/**
 * 激活账号
 * @param {*} data
 * @returns
 */
export function activeAccount(data){
  return axios({
    url:`${config.baseUrl}/account/active`,
    method: 'put',
    data
  })
}