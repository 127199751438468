<template>
  <div class="goods-wrap-material">
    <div class="goods-wrap-top">
      <div class="raw-title">
        <div class="raw-goods-title">{{ title[broadHead]}}</div>
        <div class="more-raw" @click="jump(link[broadHead])">
          更多 <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <homeClassify
        @getPage="getPage"
        :classify="classify"
        :currentIndex="currentIndex"
        :font="14"
      ></homeClassify>
    </div>
    <div class="hot-goods">
      <cableListWrap
        :orderObj="orderObj"
        :hotList="hotList"
        @sortChange="(item, value) => sortChange(item, value, broadHead)"
      >
        <cableGoodsCard
          v-for="(item, index) in hotList"
          :key="index"
          :item="item"
          :broadHead="broadHead"
        />
      </cableListWrap>
    </div>
  </div>
</template>
<script>
import { getProductList } from "@/api/rawMaterial";
import {
  AddShoppingCart
} from "@/api/shop";
import { getGoodsCategoryTree } from "@/api/rawMaterial";

// import { orderObj } from "../data/data";
import homeClassify from "@/components/homeClassify.vue";
import cableListWrap from '@/components/shop/cableListWrap.vue'
import cableGoodsCard from '@/components/shop/cableGoodsCard.vue'
export default {
  props: {
    broadHead: {
      type: Number,
      default: 1
    },
    columns: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: { homeClassify, cableListWrap, cableGoodsCard },
  data() {
    return {
      title: {
        1: '电缆原材料',
        2: '电缆商城'
      },
      link: {
        1: 'certificationHome',
        2: 'CableMall'
      },
      classify: [],
      currentIndex: null,
      orderObj: [],
      hotList: [],
      pageObj: {
        total: 0,
        currentPage: 1,
        size: 10,
        keyword: null,
      },
    };
  },
  mounted() {
    this.orderObj = this.columns
    this.getGoodsCategoryTree();
    this.skuList(this.broadHead);
  },
  methods: {
    getPage(id, index) {
      this.currentIndex = index;
      this.skuList(this.broadHead, id);
    },
    skuList(broadHead, id) {
      let data = {
        keyword: this.pageObj.keyword,
        categoryId: id,
        sorts: this.sorts,
        pageIndex: this.pageObj.currentPage,
        pageSize: this.pageObj.size,
        broadHead,
      };
      getProductList(1, data).then(({ data }) => {
        if (data.code === 0) {
          this.hotList = data.data.records.map((item) => ({
            number: 1,
            ...item,
          }));
        }
      });
    },
    //加入购物车
    AddShoppingCart(productId, num) {
      let data = {
        productId,
        number: num,
      };
      AddShoppingCart(data).then((res) => {
        const { data } = res;
        if (data.code == 0) {
          this.$message({
            message: "加入成功",
            type: "success",
          });
        }
      });
    },
    jump(name) {
      this.$router.push({
        name: name,
      });
    },
    sortChange(item, val, broadHead) {
      let obj  = this.orderObj;
      obj.forEach((value) => {
        if (value.name == item.name) {
          value.isSort = true;
          if (val !== undefined) {
            value.orderMode = val;
          } else {
            value.orderMode = value.orderMode == 0 ? 1 : 0;
          }
        } else {
          value.isSort = false;
          value.orderMode = 0;
        }
      });
      this.pageObj.currentPage = 1;
      this.sorts = [{ name: item.value, asc: !item.orderMode }];
      this.skuList(broadHead);
    },
    // 商品分类树
    getGoodsCategoryTree() {
      getGoodsCategoryTree({ broadHead: this.broadHead, displayType: 1 }).then((res) => {
        this.classify = res.data.data || [];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.goods-wrap-material {
  overflow: hidden;
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  .goods-wrap-top{
    background: #fff;
  }
  .raw-title {
    padding-top: 20px;
    position: relative;
    margin: 20px 19px;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .raw-goods-title {
      font-size: 22px;
      font-weight: 600;
      font-family: Source Han Sans CN;
      color: #333333;
    }
    .more-raw {
      color: #999;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>