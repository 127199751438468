import Vue from "vue"
const state = {
    history: []
}
const mutations = {
    SET_HISTORY(state, data) {
        state.history = data;
    },
}
const actions = {
    setHistoryList({ commit }, data) {
        return new Promise((resolve, reject) => {
            commit('SET_HISTORY', data);
            Vue.ls.set("historyList", data, 7 * 24 * 60 * 60 * 1000);
            resolve();
        })
    },
    clearHistory({ commit }){
        return new Promise((resolve, reject) => {
            commit('SET_HISTORY', []);
            Vue.ls.remove("historyList");
            resolve();
        })
    }
}
const getters = {
    getHistory(state) {
        if( state.history.length ){
            return state.history
        }else if(Vue.ls.get('historyList') && Vue.ls.get('historyList').length){
            return Vue.ls.get('historyList')
        }else{
            return []
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}