<template>
  <div>
    <homeNav>
      <div class="main-container">
        <Menu />
        <YlCarousel />
        <UserNav />
      </div>
    </homeNav>
  </div>
</template>

<script>
import homeNav from "@/components/header/homeNav.vue";
import Menu from "../components/Menu.vue";
import UserNav from "../components/UserNav.vue";
import YlCarousel from "@/components/yl-carousel/index.vue";
export default {
  components: {
    homeNav,
    Menu,
    UserNav,
    YlCarousel,
  },
};
</script>

<style scoped lang="scss">
.main-container {
  margin: 10px auto 0;
  width: 1200px;
  height: 380px;
  display: flex;
  .yl-carousel {
    flex: 1;
  }
}
</style>
