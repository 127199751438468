export default {
  path: '/userProtocol',
  meta: {
    isLogin: false
  },
  component: () => import('@/views/userProtocol/IndexView.vue'),
  children: [
    {
      path: 'use-help',
      name: 'UseHelp',
      component: () => import('@/views/userProtocol/UseHelp.vue'),
      meta: {
        title: '使用帮助'
      }
    },
    {
      path: 'conditions',
      name: 'OccupancyConditions',
      component: () => import('@/views/userProtocol/OccupancyConditions.vue'),
      meta: {
        title: '入驻条件'
      }
    },
    {
      path: 'Certification',
      name: 'Certification',
      component: () => import('@/views/userProtocol/Certification.vue'),
      meta: {
        title: '认证资料'
      }
    },
    // {
    //   path: 'questions',
    //   name: 'QA',
    //   component: () => import('@/views/userProtocol/QA.vue'),
    //   meta: {
    //     title: '常见问题'
    //   }
    // },
    {
      path: 'helpCenter',
      name: 'helpCenter',
      component: () => import('@/views/userProtocol/HelpCenter.vue'),
      meta: {
        title: '帮助中心'
      }
    },
    {
      path: 'introduction',
      name: 'PlatformIntroduction',
      component: () => import('@/views/userProtocol/PlatformIntroduction.vue'),
      meta: {
        title: '平台介绍'
      }
    },
    {
      path: 'protocol',
      name: 'UserProtocol',
      component: () => import('@/views/userProtocol/UserProtocol.vue'),
      meta: {
        title: '用户协议'
      }
    },
    {
      path: 'contact-us',
      name: 'ContactUs',
      component: () => import('@/views/userProtocol/ContactUs.vue'),
      meta: {
        title: '联系我们'
      }
    },
    {
      path: 'privacy-policy',
      name: 'PrivacyPolicy',
      component: () => import('@/views/userProtocol/PrivacyPolicy.vue'),
      meta: {
        title: '隐私政策'
      }
    }
  ]
}