<template>
  <div class="shop-container">
    <table style="table-layout: fixed; width: 1200px; height: 60px">
      <thead>
        <tr>
          <th
            class="sort"
            v-for="item in orderObj"
            :key="item.id"
            :class="item.pattern"
          >
            <div class="goods-pattrn col3" v-if="!item.sort">
              <div
                class="name"
                :class="{ on: item.isSort }"
                @click="handlerSort(item)"
              >
                {{ item.name }}
              </div>
              <div class="goods-icon" :class="{ on: item.isSort }">
                <span
                  @click="handlerSort(item, 0)"
                  class="arrUp"
                  :class="{ active: item.orderMode == 0 }"
                ></span>
                <span
                  @click="handlerSort(item, 1)"
                  class="arrDown"
                  :class="{ active: item.orderMode == 1 }"
                ></span>
              </div>
            </div>
            <div class="goods-pattrn col3" v-else>{{ item.name }}</div>
          </th>
        </tr>
      </thead>
    </table>
    <div class="goods-info">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["orderObj", "hotList"],
  methods: {
    handlerSort(item, value) {
      this.$emit("sortChange", item, value);
    },
  }
};
</script>

<style lang="scss" scoped>
.shop-container{
  background: #f5f5f5;
}
table {
  width: 100%;
  text-align: center;
  border-spacing: 0px;
  font-size: 14px;
  color: #999;
}
thead {
  height: 60px;
  background: #f6fcff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
}
.sort {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 700;
  color: #666666;
  cursor: pointer;
  .goods-pattrn {
    display: flex;
    align-items: center;
    justify-content: center;
    .goods-icon {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      .arrUp {
        width: 0;
        height: 0;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-bottom: 5px solid #a5a5a5;
        cursor: pointer;
      }

      .arrDown {
        width: 0;
        height: 0;
        margin-top: 2px;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-top: 5px solid #a5a5a5;
        cursor: pointer;
      }
      &.on {
        .active {
          border-bottom-color: #e60012;
          border-top-color: #e60012;
        }
      }
    }
  }
}
.goods-info {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  min-height: 200px;
  .el-empty{
    margin: 0 auto;
  }
}
</style>
