import axios from '@/utils/http'
import config from '@/config/index';
let {POWER_CABLE_BASE} = config;


// 获取消息列表
export  function getMessagePage(params) {
  return axios({
    url: `${POWER_CABLE_BASE}/msg/page`,
    method: 'get',
    params
  })
}

// 查看消息
export  function getMessage(id) {
  return axios({
    url: `${POWER_CABLE_BASE}/msg/read/${id}`,
    method: 'get',
  })
}

// 未读消息数量
export  function getMessageUnread() {
  return axios({
    url: `${POWER_CABLE_BASE}/msg/unread`,
    method: 'get',
    errorHandle: ()=>{return Promise.resolve()},
    nprogress: false
  })
}

// 消息配置列表
export  function getMessageConfigList() {
  return axios({
    url: `${POWER_CABLE_BASE}/msg/config/all`,
    method: 'get',
  })
}
