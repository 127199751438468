//电缆原材料表格表头
export const orderObj = [
  {
    name: "商品信息",
    value: "productName",
    orderMode: 2,
    isSort: false,
    pattern: "product",
  },
  {
    name: "品牌",
    value: "brand",
    orderMode: 2,
    isSort: false,
    pattern: "narrow",
  },
  {
    name: "供应商",
    value: "supplier",
    orderMode: 2,
    isSort: false,
    pattern: "narrow",
  },
  {
    name: "参考价格",
    value: "price",
    orderMode: 2,
    sort: true,
    pattern: "narrow",
  },
 
]
export const collageList = [{
  name: "原料名称",
  isSort: false,
  pattern: "wide",
},
{
  name: "品牌",
  isSort: false,
  pattern: "wide",
},
{
  name: "商品规格",
  isSort: false,
  pattern: "wide-super",
},
{
  name: "拼单价",
  isSort: false,
  pattern: "narrow",
},
{
  name: "供应商",
  isSort: false,
  pattern: "narrow",
},
{
  name: "仓库",
  isSort: false,
  pattern: "wide",
},
{
  name: "参与人数",
  isSort: false,
  pattern: "narrow",
},
{
  name: "已拼数量",
  isSort: false,
  pattern: "narrow",
}]

//电缆商城表格表头

export const cableList = [
  {
    name: "商品信息",
    value: "productName",
    orderMode: 2,
    isSort: false,
    pattern: "product",
  },
  {
    name: "品牌",
    value: "brand",
    orderMode: 2,
    isSort: false,
    pattern: "narrow",
  },
  {
    name: "供应商",
    value: "supplier",
    orderMode: 2,
    isSort: false,
    pattern: "narrow",
  },
  {
    name: "参考价格",
    value: "price",
    orderMode: 2,
    sort: true,
    pattern: "narrow",
  },
 
]