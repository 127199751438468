import axios from '@/utils/http'
import config from '@/config/index'

//查询账户信息
export function getElectronicInfo(params) {
  return axios({
    url: `${config.POWER_CABLE_PAY}/payAccount/queryAccount`,
    method: 'get',
    params
  })
}
// 电子账户开通资料信息
export function getElectronicAcctInfo(params) {
  return axios({
    url: `${config.baseUrl}/electronic/acct/info`,
    method: 'get',
    params
  })
}

// 开通电子账户
export function electronicBankRegister(data) {
  return axios({
    url: `${config.POWER_CABLE_PAY}/payAccount/register`,
    method: 'post',
    data
  })
}
// 解绑
export function unbindPA(data) {
  return axios({
    url: `${config.baseUrl}/electronic/bank/unbind/PA`,
    method: 'put',
    data
  })
}

// 绑卡
export function BankBindPA(data) {
  return axios({
    url: `${config.baseUrl}/electronic/bank/bind/PA`,
    method: 'post',
    data
  })
}

// 查询鉴权转账结果

export function BankTransferPA(params) {
  return axios({
    url: `${config.POWER_CABLE_PAY}/payAccount/queryAmountTransfer`,
    method: 'get',
    params,
    hideTip: true
  })
}

// 回填校验金额
export function BankTransferConfirmPA(data) {
  return axios({
    url: `${config.POWER_CABLE_PAY}/payAccount/chenkAmount`,
    method: 'post',
    data,
    hideTip: true
  })
}
// 修改手机号
export function setMobile(data) {
  return axios({
    url: `${config.baseUrl}/electronic/mobile/set/PA`,
    method: 'post',
    data
  })
}
//修改手机号发送验证码
export function getSimChangeMobile(params){
  return axios({
    url: `${config.baseUrl}/electronic/mobile/change/code/PA`,
    method: 'get',
    params
  })
}

// 银行账号列表
export function getBankAccounts(params){
  return axios({
    url: `${config.baseUrl}/bank/accounts`,
    method: 'get',
    params
  })
}

// GET 查询银行列表
export function getBankList(params){
  return axios({
    url: `${config.POWER_CABLE_PAY}/bankOrg/selectAll`,
    method: 'get',
    params
  })
}

// 设置密码
export function setElectronicPassword(data){
  return axios({
    url: `${config.baseUrl}/electronic/password/set`,
    method: 'post',
    data
  })
}
export function setPayAcctPassword(data){
  return axios({
    url: `${config.POWER_CABLE_PAY}/payAccount/setPassword`,
    method: 'post',
    data
  })
}

// GET 交易查询分页列表
export function getOrderPayPage(params){
  return axios({
    url: `${config.baseUrl}/trade/page`,
    method: 'get',
    params
  })
}

// 提现
export function tradeWithdrawPA(data){
  return axios({
    url: `${config.baseUrl}/trade/withdraw/PA`,
    method: 'post',
    data,
    hideTip: true
  })
}

/**
 * 转账
 * @param {*} data 
 * @returns 
 */
export function tradeTransferPA(data){
  return axios({
    url: `${config.baseUrl}/trade/transfer/PA`,
    method: 'post',
    data
  })
}

// 注销账户
export function logoutPA(data){
  return axios({
    url: `${config.baseUrl}/electronic/logout/PA`,
    method: 'post',
    data
  })
}

// 交易详情
export function tradeDetail(params){
  return axios({
    url: `${config.baseUrl}/trade/detail/${params.id}`,
    method: 'get',
  })
}

// 发送支付提现验证码
export function getTradeSimPa(params){
  return axios({
    url: `${config.baseUrl}/trade/send/sim/PA`,
    method: 'get',
    params
  })
}

// 查询平台账户信息
export function queryAccount(params){
  return axios({
    url: `power-cable-pay/platformAccount/queryAccount`,
    method: 'get',
    params
  })
}

// 确认支付，线上支付，输入密码
export function orderPay(data){
  return axios({
    url: `${config.baseUrl}/order/pay/confirm`,
    method: 'post',
    data
  })
}

// 订单支持的支付方式
export function orderPaySupport(params){
  return axios({
    url: `${config.baseUrl}/order/pay/support/${params.orderId}`,
    method: 'get',
    params
  })
}

//开通流程
export function getRegisterProcessPA(params){
  return axios({
    url: `${config.POWER_CABLE_PAY}/payAccount/registerProcess`,
    method: 'get',
    params:{...params,accountType:'PA'}
  })
}

// 收支走势
export function getPayReceiptTrend(params){
  return axios({
    url: `${config.POWER_CABLE_PAY}/account/reportAccess`,
    method: 'get',
    params
  })
}

// 支付类型占比
export function getPayRatio(params){
  return axios({
    url: `${config.baseUrl}/report/payRatio`,
    method: 'get',
    params
  })
}

// 收付款统计报表
export function getPayAndReceipt(params){
  return axios({
    url: `${config.baseUrl}/report/payAndReceipt`,
    method: 'get',
    params
  })
}

// 提现统计
export function getWithdraw(params){
  return axios({
    url: `${config.baseUrl}/report/withdraw`,
    method: 'get',
    params
  })
}

export function saveBank(data){
  return axios({
    url: `${config.POWER_CABLE_PAY}/bankAccountRecord/saveBankAccount`,
    method: 'post',
    data
  })
}

//删除银行卡
export function delBank(id){
  return axios({
    url: `${config.POWER_CABLE_PAY}/bankAccountRecord/del`,
    method: 'post',
    params: {id}
  })
}

// 查询账户的绑定信息
export function bankBindList(params){
  return axios({
    url: `${config.baseUrl}/electronic/bind/list`,
    method: 'get',
    params
  })
}

// 获取电子账户绑定的银行卡
export function getElectronicBindBanks(params){
  return axios({
    url: `${config.POWER_CABLE_PAY}/bankAccount/getPayAccountBinds`,
    method: 'get',
    params
  })
}
// 查询余额信息
export function getElectronicBalance(params){
  return axios({
    url: `${config.POWER_CABLE_PAY}/payAccount/queryAcctBalance`,
    method: 'get',
    params
  })
}

// 查询账户信息列表
export function getElectronicList(params){
  return axios({
    url: `${config.POWER_CABLE_PAY}/payAccount/banks`,
    method: 'get',
    params
  })
}

// 是否可以绑卡
export function isBindAccount(params){
  return axios({
    url: `power-cable-pay/bankAccount/isBindAccount`,
    method: 'get',
    params
  })
}


export function getBankNameByCard(params) {
  return axios({
    url: `${config.POWER_CABLE_PAY}/bankOrg/getBankCode`,
    method: 'get',
    params,
    hideTip: true
  })
}

// 撤销绑定 只有绑卡状态才能撤销
export function revokeBind(params) {
  return axios({
    url: `${config.POWER_CABLE_PAY}/bankAccount/revokeBind`,
    method: 'get',
    params
  })
}

export const getReportBalance = (params) => {
  return axios({
    method: 'get',
    url: `${config.POWER_CABLE_PAY}/account/reportBalance`,
    params
  })
}

// 查询交易记录
export const getTradeRecord = (params) => {
  return axios.get(`${config.POWER_CABLE_PAY}/payRecord/queryPayRecord`, { params })
}

export const getReportWithdraw = (params) => {
  return axios({
    method: 'get',
    url: `${config.POWER_CABLE_PAY}/account/reportWithdraw`,
    params
  })
}

export const getReportBrandCard = (params) => {
  return axios({
    method: 'get',
    url: `${config.POWER_CABLE_PAY}/account/reportBrandCard`,
    params
  })
}

// 金融产品列表
export const getFinanceProductList = (params) => {
  return axios({
    method: 'get',
    url: `${config.baseUrl}/finance/product/list`,
    params
  })
}

// 询问，查看是否可以申请
export const getFinanceProductApply = (params) => {
  return axios({
    method: 'get',
    url: `${config.baseUrl}/financing/apply/ask`,
    params
  })
}

// 获取申请
export const getFinanceProductApplyInfo = (params) => {
  return axios({
    method: 'get',
    url: `${config.baseUrl}/financing/apply/obtain`,
    params
  })
}

// 确认合同内容
export const getFinanceProductApplyConfirm = (data) => {
  return axios({
    method: 'post',
    url: `${config.baseUrl}/financing/apply/confirm`,
    data
  })
}

// 获取合同签署链接
export const getFinanceProductApplySign = (sn) => {
  return axios({
    method: 'get',
    url: `${config.baseUrl}/contract/signurl/${sn}`
  })
}

// 融资产品申请资料提交
export const postFinanceProductApplySubmit = (data) => {
  return axios({
    method: 'post',
    url: `${config.baseUrl}/financing/apply/submit`,
    data
  })
}

// 合同信息
export const getFinanceProductApplyContract = (id) => {
  return axios({
    method: 'get',
    url: `${config.baseUrl}/finance/contract/info/${id}`,
  })
}

// 赊销申请可选企业列表
export const getApplyQuotaCompanyList = (params) => {
  return axios({
    method: 'get',
    url: `${config.baseUrl}/financing/apply/quota/companies`,
    params
  })
}

// 赊销产品
export const getFinanceProductQuotaList = (params) => {
  return axios({
    method: 'get',
    url: `${config.baseUrl}/finance/product/list/charge`,
    params
  })
}