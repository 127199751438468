import axios from '@/utils/http'
import config from '@/config/index'

//添加购物车

export function addCart(data) {
  return axios({
    url: `${config.baseUrl}//shoppingCart/add`,
    method: 'post',
    data
  })
}

// 更新购物车选中

export function updateCart(data) {
  return axios({
    url: `${config.baseUrl}/shoppingCart/update/selected`,
    method: 'post',
    data
  })
}

//删除购物车

export function deleteCart(data) {
  return axios({
    url: `${config.baseUrl}/shoppingCart`,
    method: 'DELETE',
    data
  })
}

// 购物车列表(按店铺分组）

export function cartList(params) {
  return axios({
    url: `${config.baseUrl}/shoppingCart/page/group`,
    method: 'get',
    params
  })
}

// 批量添加购物车

export function batchAddCart(data) {
  return axios({
    url: `${config.baseUrl}/shoppingCart/batch/add`,
    method: 'post',
    data
  })
}

// 获取购物车数量

export function getCartNum() {
  return axios({
    url: `${config.baseUrl}/shoppingCart/number`,
    method: 'get'
  })
}