<!--
支持外链的RouterLink, 只需要包装一次默认的router-link.
-->
<template>
  <!-- 以斜杠/开头的路径会被判定为内链, 否则是外链 你可以自己修改此逻辑 -->
  <a v-if="isExternal" :href="$attrs.to" :target="windowName" @click="jump">
    <slot></slot>
  </a>
  <router-link v-else v-bind="$attrs">
    <slot></slot>
  </router-link>
</template>
<script>
  export default {
    name: 'YlRouterLink',
    computed: {
      windowName() {
        return this.$store.state.app.windowName
      },
      isExternal(){
        return typeof this.$attrs.to === 'string' && this.$attrs.to.startsWith('http')
      }
    },
    methods: {
      jump(e){
        e.preventDefault()
        if(this.$attrs.companyStatus == 5) {
          window.location.href = this.$attrs.to
        } else {
          this.$message.warning('请先进行企业认证!')
          this.$router.push('/certification/company')
        }
      }
    }
  }
</script>