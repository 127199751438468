import axios from '@/utils/http'
import config from '@/config/index'

// 账号保存（编辑）
export function saveAccount(data) {
  return axios({
    url: `${config.baseUrl}/account/personal/save`,
    method: 'post',
    data
  })
}

// 账户信息
export function getAccountInfo(params) {
  return axios({
    url: `${config.baseUrl}/account/info`,
    method: 'get',
    params
  })
}

// 企业黄页保存
export function saveYellowPage(data) {
  return axios({
    url: `${config.baseUrl}/yellowPage/save`,
    method: 'post',
    data
  })
}

// 企业黄页信息(查看）
export function getyellowPageInfo(params) {
  let url
  if(params?.id){
    url = `${config.baseUrl}/yellowPage/info/${params.id}`
  } else {
    url =  `${config.baseUrl}/yellowPage/info`
  }
  return axios({
    url: url,
    method: 'get'
  })
}

// 申请开通黄页
export function getYellowPageApply() {
  return axios({
    url: `${config.baseUrl}/yellowPage/apply`,
    method: 'get'
  })
}

// 企业信息(查看）
export function getCompanyCertifyInfo(params, hideTip=false) {
  return axios({
    url: `${config.baseUrl}/companyCertify/info`,
    method: 'get',
    params,
    hideTip
  })
}

// 查询企业发布信息
export function getCompanyPublish(params) {
  let url
  if(params?.id){
    url = `${config.baseUrl}/companyPublish/all/${params.id}`
  } else {
    url =  `${config.baseUrl}/companyPublish/all`
  }
  return axios({
    url,
    method: 'get',
    params
  })
}


// 企业公告保存
export function saveCompanyPublish(data) {
  return axios({
    url: `${config.baseUrl}/companyPublish/save`,
    method: 'post',
    data
  })
}
//企业公告删除
export function delCompanyPublish(id) {
  return axios({
    url: `${config.baseUrl}/companyPublish/${id}`,
    method: 'delete'
  })
}