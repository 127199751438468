<template>
  <div class="product-container">
    <YlCarousel height="200px" :typeParams="typeParams"/>
    <CableRawList :columns="columns" :broadHead='broadHead' />
  </div>
</template>

<script>
import YlCarousel from "@/components/yl-carousel/index.vue";
import CableRawList from '../components/CableRawList.vue';
export default {
  props: {
    typeParams: {
      type: String,
      default: '1-1'
    },
    columns: {
      type: Array,
      default() {
        return []
      }
    },
    broadHead: {
      type: Number,
      default: 1
    },
  },
  components: {
    YlCarousel,
    CableRawList
  },
}
</script>

<style scoped lang='scss'>
.product-container{
  width: 1200px;
  margin: 10px auto;
  .yl-carousel{
    margin: 10px 0;
  }
}
</style>