
import Vue from 'vue'
import App from './App.vue'
import Storage from 'vue-ls'
import router from './router'
import store from './store'
import './permission'
import 'normalize.css'
import ElementUI from 'element-ui';
import './element-variables.scss'
import axios from './utils/http'
Vue.prototype.$http = axios

import QRcode from './components/QRcode'
Vue.use(QRcode);

import Directives from './directive/index'
Vue.use(Directives)
import rules from './utils/rules'
Vue.prototype.$rules = rules

import * as filters from './filters' // global filters

import TypeNav from '@/components/YlTitle'
Vue.component(TypeNav.name, TypeNav)


import moment from 'moment'
Vue.prototype.$moment = moment
moment.locale("zh-CN");
import Print from 'vue-print-nb'
// Global instruction 
Vue.use(Print);
Vue.use(Storage, {
  namespace: 'ylw_',
  name: 'ls',
  storage: 'local'
})
Vue.use(ElementUI);

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.config.productionTip = false

let app = new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    window.name = this.$store.state.app.name
  }
})
app.$mount('#app')
import '@/utils/reload'