import axios from '@/utils/http'
import config from '@/config/index'

// 按类型分组获取文章列表
export function getArticleGroupList(params) {
  return axios({
    url: `${config.baseUrl}/article/group/list/${params.size}`,
    method: 'get'
  })
}
// 文章详情--根据id显示
export function getArticleInfoById(id) {
  return axios({
    url: `${config.baseUrl}/article/getInfo/${id}`,
    method: 'get',
  })
}

// 文章管理--条件查询分页
export function getArticleList(params) {
  return axios({
    url: `${config.baseUrl}/article/getArticle`,
    method: 'get',
    params
  })
}
export function getHotNews(){
  return axios({
    url: `${config.baseUrl}/article/hotNews`,
    method: 'get',
  })
}