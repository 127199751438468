import axios from '@/utils/http'
import config from '@/config/index'

// 热门原材料
export function getRawMaterialPageHot(params, pageIndex, pageSize) {
  return axios({
    url: `${config.baseUrl}/rawMaterial/page/hot/${pageIndex}/${pageSize}`,
    method: 'get',
    params
  })
}
// 商品分类树
export function getGoodsCategoryTree(params) {
  return axios({
    url: `${config.baseUrl}/goods/category/tree`,
    method: 'get',
    params
  })
}
//商品分类树
export function allGoodsCategoryTree(){
  return axios({
    url:`${config.baseUrl}/goods/category/all/tree`,
    method:'get'
  })
}
// 保存原材料
export function saveMaterial(data) {
  return axios({
    url: `${config.baseUrl}/manager/material/save`,
    method: 'post',
    data
  })
}

// 分类下的属性列表(用于下拉列表等)

export function getAttributeListByCategoryId(params) {
  return axios({
    url: `${config.baseUrl}/product/attr/category/list`,
    method: 'get',
    params
  })
}
export function getProductList(type , data){
  return axios({
    headers:{
      identity:type
    },
    url:`${config.baseUrl}/product/page`,
    method:'post',
    data
  })
}