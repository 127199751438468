<template>
  <div class="index-page">
    <MainContainer/>
    <companyMsg />
    <PriceContainer/>
    <ProductContainer :broadHead='1' typeParams='1-1' :columns='orderObj' />
    <ProductContainer :broadHead='2' typeParams='1-2' :columns='cableList' />
    <NewsContainer/>
    <CompanyShow/>
    <Partner/>
    <shop-footer></shop-footer>
  </div>
</template>

<script>
import ShopFooter from "@/components/shop/ShopFooter.vue";

import { orderObj, cableList } from "./data";
// 组件
import MainContainer from './MainContainer'
import PriceContainer from './PriceContainer'
import ProductContainer from './ProductsContainer'
import  NewsContainer  from './NewsContainer'
import CompanyShow from './CompanyShow'
import Partner from './Partner'
import companyMsg from './companyMsg'

export default {
  components: {
    MainContainer,
    PriceContainer,
    ProductContainer,
    NewsContainer,
    CompanyShow,
    Partner,
    ShopFooter,
    companyMsg
  },
  data() {
    return {
      cableList,
      orderObj,
    };
  },
};
</script>
<style lang="scss" scoped>

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.index-page {
  background: #f5f5f5;
}

</style>
