<template>
  <div class="goods-menu">
    <div v-for="(item, index) in list" :key="index">
      <div class="goods-title">{{ item.name }}</div>
      <div class="goods-material">
        <div class="grid-container" v-for="itemChild in listLimit(item.children, item.broadHead)" :key="itemChild.id" :title="itemChild.name">
          <RouterJumpVue
            :to="{
              path: enumPath[itemChild.broadHead],
              query: { categoryId: itemChild.id },
            }"
            tag="div"
            :text="itemChild.name"
          ></RouterJumpVue>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RouterJumpVue from "@/components/RouterJumpVue.vue";
import { allGoodsCategoryTree } from '@/api/goods'
export default {
  components: { RouterJumpVue },
  data() {
    return {
      list: [],
      enumPath: {
        1: '/enterprise/home',
        2: '/cable-mall',
        3: '/other-mall'
      }
    };
  },
  mounted() {
    this.allGoodsCategoryTree()
  },
  methods: {
    allGoodsCategoryTree() {
      allGoodsCategoryTree().then(({data: res})=>{
        if(res.code == 0) {
          this.list = res.data
        }
      })
    },
    // 数据处理限制条数
    listLimit(list, broadHead, num = 8) {
      if(broadHead == 3) {
        num = 4
      }
      if (list.length > num) {
        return list.slice(0, num-1).concat([{
          broadHead: broadHead,
          name: '更多'
        }]);
      } else {
        return list;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.goods-menu {
  background: #fff;
  width: 210px;
  margin-right: 10px;
  padding: 15px 20px;

  .goods-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 600;
    color: #333333;
    margin-bottom: 13px;

    &::before {
      margin-right: 5px;
      content: "";
      display: block;
      height: 12px;
      width: 2px;
      background: #e60012;
    }
  }

  .goods-material {
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    display: flex;
    flex-wrap: wrap;
    .grid-container {
      width: 48%;
      margin-bottom: 12px;
      &:nth-child(2n-1){
        margin-right: 4%;
      }
      > div {
        ::v-deep a{
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
    }
  }
  ::v-deep a:hover{
    color: #e60012;
  }
}

</style>