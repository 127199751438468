import axios from '@/utils/http'
import config from '@/config/index'
// 原材料分页列表
export function getRawMaterialPage (params, data) {
  return axios({
    url: `${config.baseUrl}/rawMaterial/page/${params.pageIndex}/${params.pageSize}`,
    method: 'post',
    data
  })
}

//原材料分页列表(卖家后台)
export function getManagerMaterialPage (params) {
  return axios({
    url: `${config.baseUrl}/product/page/seller`,
    method: 'get',
    params
  })
}
// 修改上架下架状态
export function changeRawMaterialStatus (params) {
  return axios({
    url: `${config.baseUrl}/product/shelf/${params.id}/${params.status}`,
    method: 'get'
  })
}


// 商品分类树
export function getGoodsCategoryTree(params){
  return axios({
    url: `${config.baseUrl}/goods/category/tree`,
    method: 'get',
    params
  })
}
// 订单分页列表
export function getOrderPage(params) {
  return axios({
    url: `${config.baseUrl}/order/page/${params.pageIndex}/${params.pageSize}`,
    method: 'get',
    params
  })
}
// 订单详情
export function getOrderDetail(params) {
  return axios({
    url: `${config.baseUrl}/order/detail/${params.id}`,
    method: 'get'
  })
}
// 订单发货
export function orderSend(data) {
  return axios({
    url: `${config.baseUrl}/order/distribution/send`,
    method: 'post',
    data
  })
}
// 订单删除
export function orderDel(id) {
  return axios({
    url: `${config.baseUrl}/order/${id}`,
    method: 'delete'
  })
}

// 确认支付结果
export function orderPayValidate(id, status) {
  let params ={}
  if(status){
    params = {
      status
    }
  }
  return axios({
    url: `${config.baseUrl}/order/pay/validate/${id}`,
    params,
    method: 'PUT'
  })
}
// 详情页面确人收货（送达）
export function orderReceipt(id) {
  return axios({
    url: `${config.baseUrl}/order/distribution/receipt/${id}`,
    method: 'PUT'
  })
}


// 真实性审核提交
export function checkMaterial(data) {
  return axios({
    url: `${config.baseUrl}/manager/material/check/submit`,
    method: 'post',
    data
  })
}


// （送货人）分页列表

export function getShipperPage(params){
  return axios({
    url:  `${config.POWER_CABLE_LOGISTICS}/shipper/page`,
    method: 'get',
    params
  })
}

// （送货人）保存或更新
export function saveShipper(data){
  return axios({
    url:  `${config.POWER_CABLE_LOGISTICS}/shipper/save`,
    method: 'POST',
    data
  })
}

// 仓库管理分页列表
export function getWareInfoPage(params){
  return axios({
    url:  `${config.baseUrl}/wareInfo/page/${params.pageIndex}/${params.pageSize}`,
    method: 'get',
    params
  })
}
// 仓库管理（保存）
export function saveWareInfo(data){
  return axios({
    url:  `${config.baseUrl}/wareInfo/save`,
    method: 'POST',
    data
  })
}

// 仓库管理（删除）
export function delWareInfo(id){
  return axios({
    url:  `${config.baseUrl}/wareInfo/${id}`,
    method: 'DELETE'
  })
}

// （仓库管理）启用禁用
export function switchWareInfoStatus(id){
  return axios({
    url:  `${config.baseUrl}/wareInfo/switchStatus/${id}`,
    method: 'PUT'
  })
}

// 物流管理分页列表
export function getOrderDistribution(params){
  return axios({
    url:  `${config.baseUrl}/order/distribution/page`,
    method: 'get',
    params
  })
}
// 物流信息
export function getOrderDistributionInfo(id){
  return axios({
    url:  `${config.baseUrl}/order/distribution/info/${id}`,
    method: 'get'
  })
}
// 物流信息
export function saveLogisticsDynamic(data){
  return axios({
    url:  `${config.baseUrl}/logistics/dynamic`,
    method: 'post',
    data
  })
}

// 店铺主推材料保存
export function savePushMainMaterial(data){
  return axios({
    url:  `${config.baseUrl}/main/sale/saves`,
    method: 'POST',
    data
  })
}
// 店铺主推商品（所有数据）
export function getPushMainMaterialAll(){
  return axios({
    url:  `${config.baseUrl}/main/sale/all`,
    method: 'get'
  })
}

// 合同列表（合同管理）
export function getOrdercontractList(params){
  return axios({
    url:  `${config.baseUrl}/order/contract/page`,
    method: 'get',
    params
  })
}
// 合同详情
export function getOrdercontractDetail(orderId){
  return axios({
    url:  `${config.baseUrl}/order/contract/detail/${orderId}`,
    method: 'get'
  })
}

// 确认订单合同内容（合同管理）
export function confirmOrderContract(data){
  return axios({
    url:  `${config.baseUrl}/order/contract/confirm`,
    method: 'POST',
    data
  })
}
// 在线签章（合同管理）
export function signOrderContract(params){
  return axios({
    url:  `${config.baseUrl}/order/contract/sign/${params.id}`,
    method: 'put',
    params
  })
}

// 合同模板列表
export function getContractTemplatetList(params){
  return axios({
    url:  `${config.baseUrl}/contractTemplate/page`,
    method: 'get',
    params,
  })
}

// 合同模板删除
export function delContractTemplate(id){
  return axios({
    url:  `${config.baseUrl}/contractTemplate/${id}`,
    method: 'DELETE'
  })
}

// 合同模板保存
export function saveContractTemplate(data){
  return axios({
    url:  `${config.baseUrl}/contractTemplate/save`,
    method: 'post',
    data
  })
}

// 查看模板
export function getContractTemplateInfo(id){
  return axios({
    url:  `${config.baseUrl}/contractTemplate/info/${id}`,
    method: 'GET'
  })
}

// 模板启用禁用
export function switchContractTemplate(id, status){
  return axios({
    url:  `${config.baseUrl}/contractTemplate/enable/${id}/${status}`,
    method: 'PUT'
  })
}
//获取原材料大盘价格
export function getNowPrice(categoryId){
  return axios({
    url:`${config.baseUrl}/categoryTrend/getNowPrice?categoryId=${categoryId}`
  })
}
//快捷修改，无需提审
export function getQuickMaterial(data){
  return axios({
    url:`${config.baseUrl}/rawMaterial/modify/quick`,
    method:'put',
    data
  })
}
//批量上传文件
 export function getbatchList(data){
  return axios({
    url:`${config.baseUrl}/rawMaterial/batchSave`,
    method:'post',
    data
  })
 }
 //批量上传数据，数据预览
 export function getRawMaterialData(data){
  return axios({
    url:`${config.baseUrl}/rawMaterial/showBatchData`,
    method:'post',
    data
  })
 }
 //批量上传
 export function uploadBatchImage(data){
  return axios({
    url:`${config.baseUrl}/rawMaterial/batchImages`,
    method:'post',
    data
  })
 }
 //批量上传图片，材料详情
 export function getSubmitList(data){
  return axios({
    url:`${config.baseUrl}/manager/material/check/submitList`,
    method:"post",
    data
  })
 }
 //批量提审
 export function getSubmitBatchList(data){
  return axios({
    url:`${config.baseUrl}/manager/material/check/submitList`,
    method:'post',
    data
  })
 }

 // 保存银行卡账户信息
 export function saveBankAccount(data){
  return axios({
    url:`${config.baseUrl}/bank/save`,
    method:'post',
    data
  })
 }

