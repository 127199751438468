import HomeView from '@/views/home/index.vue'
export default [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      isLogin: false,
      title: '首页',
    }
  },
  {
    path: '/cable-mall',
    name: 'CableMall',
    meta: {
      isLogin: false,
      title: '易缆商城',
      broadHead: 2
    },
    component: () => import('@/views/cableMall/index.vue')
  },
  {
    path: '/enterprise/home',
    name: 'certificationHome',
    meta: {
      isLogin: false,
      title: '易缆原料',
      broadHead: 1
    },
    component: () => import('@/views/cableMall/index.vue')
  },
  {
    path: '/other-mall',
    name: 'OtherMall',
    meta: {
      isLogin: false,
      title: '其它',
      broadHead: 3
    },
    component: () => import('@/views/cableMall/index.vue')
  },
  {
    path: '/order/preview',
    name: 'Preview',
    meta: {
      title: '订单预览'
    },
    component: () => import('@/views/order/Preview.vue')
  },
  {
    path: '/search-results',
    name: 'SearchResults',
    meta: {
      isLogin: false,
      title: '搜索页',
      isShowHeader: true
    },
    component: () => import('@/views/SearchResults/index.vue')
  },
  {
    path: '/cable-finance',
    name: 'CableFinance',
    meta: {
      isLogin: false,
      title: '易缆金融'
    },
    component: () => import('@/views/cableFinance/index.vue')
  },
  {
    path: '/cable-finance/apply/:id',
    name: 'financeApply',
    meta: {
      isLogin: true
    },
    component: () => import('@/views/cableFinance/apply/index.vue')
  },
  {
    path: '/cable-logistics',
    name: 'CableLogistics',
    meta: {
      isLogin: false,
      title: '易缆物流'
    },
    component: () => import('@/views/cableLogistics/index.vue')
  },
  {
    path: '/logistics_activation',
    name: 'LogisticsActivation',
    meta: {
      isLogin: false,
      title: '物流开通'
    },
    
    component: () => import('@/views/cableLogistics/logistics_activation.vue')
  },
  {
    path: '/send_cable_logistics',
    name: 'SendLogistics',
    meta: {
      isLogin: false,
      title: '物流发货'
    },
    component: () => import('@/views/cableLogistics/send.vue')
  },
  {
    path: '/articleList',
    name: 'articleList',
    meta: {
      isLogin: false,
      title: '易缆资讯',
      keepAlive: true
    },
    component: () => import('@/views/officialWebsite/ArticleList.vue')
  },
  {
    path: '/articlePage/:id',
    name: 'ArticlePage',
    meta: {
      isLogin: false
    },
    component: () => import('@/views/officialWebsite/ArticlePage.vue')
  },
  {
    path: '/companyList',
    name: 'companyList',
    meta: {
      isLogin: false,
      title: '易缆入驻',
      keepAlive: true
    },
    component: () => import('@/views/officialWebsite/CompanyList.vue')
  },
  {
    path: '/enterprisePage/:id',
    name: 'EnterprisePage',
    redirect: '/enterprisePage/:id/1',
    meta: {
      isLogin: false
    },
    component: () => import('@/views/officialWebsite/empty.vue'),
    children: [
      {
        path: ':show',
        name: 'EnterprisePageShow',
        component: () => import('@/views/officialWebsite/EnterprisePage.vue'),
      },
    ]
    // component: () => import('@/views/officialWebsite/EnterprisePage.vue')
  },
]