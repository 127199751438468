<template>
  <div class="news-container">
    <YlCarousel height="200px" typeParams="1-3" />
    <NewsListContainer/>
  </div>
</template>

<script>
import YlCarousel from "@/components/yl-carousel/index.vue";
import NewsListContainer from './NewsListContainer.vue';
export default {
  components: {
    YlCarousel,
    NewsListContainer
  },
}
</script>

<style scoped lang='scss'>
.news-container{
  width: 1200px;
  margin: 10px auto;
}
</style>