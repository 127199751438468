import router from "./router";
import store from "./store";
import { getToken, getUserInfo, setToken } from "@/utils/auth";
import { getRegisterProcessPA } from "@/api/finance";
import { getyellowPageInfo } from "@/api/personcenter";

// 拿用户信息判断certifyStatus，有没有买卖家资质，
//如果没有，next()
function verifyStatus() {
  const { companyStatus } = getUserInfo();
  if (companyStatus != 5) {
    return `/certification/company`;
  } else {
    return;
  }
}

function getTitle(to) {
  let name = getUserInfo().company?.name;
  if (name) {
    document.title = to.meta.title ? to.meta.title + "-" + name : "易缆网";
  } else {
    document.title = "易缆网";
  }
}
router.beforeEach(async (to, from, next) => {
  if (to.query.token) {
    setToken(to.query.token)
    let query = to.query
    delete query.token
    next({ path: to.path, query})
  }
  const hasToken = store.state.user.token || getToken();

  if (hasToken) {
    if (to.path === "/login") {
      next({ path: "/" });
    } else {
      const userInfo = getUserInfo();
      if (userInfo && userInfo.mobile) {
        if (to.fullPath.includes("/buyerCenter") || to.meta.needbuyer) {
          next(verifyStatus());
        } else if (to.fullPath.includes("/sellerCenter")) {
          const path = verifyStatus();
          if (path) {
            next(path);
          } else {
            const data = await getyellowPageInfo();
            if (data.data.data.id) {
              next();
            } else {
              next("/center/yellow-pages");
            }
          }
        } else if (to.fullPath.includes("/finance")) {
          let process = await getRegisterProcessPA();
          if (process.data.code == 0) {
            switch (process.data.data) {
              case -1:
                next();
                break;
              case 0:
                if (to.path == "/finance/account/further-information") {
                  next();
                } else {
                  next({ path: "/finance/account/further-information" });
                }
                break;
              case 1:
                if (to.path == "/finance/account/password") {
                  next();
                } else {
                  next({ path: "/finance/account/password" });
                }
                break;
              case 1.1:
                if (to.path == "/finance/cardBinding/add") {
                  next();
                } else {
                  next({ path: "/finance/cardBinding/add" });
                }
                break;
              case 2:
                if (to.path == "/finance/cardBinding/check-money") {
                  next();
                } else {
                  next({ path: "/finance/cardBinding/check-money" });
                }

                break;
              case 3:
                next();
                break;
              default:
                next();
            }
            next();
          } else {
            next({ path: "/" });
          }
        } else {
          next();
        }
      } else {
        try {
          await store.dispatch("user/getInfo");
          next({ ...to, replace: true });
        } catch (error) {
          await store.dispatch("user/resetToken");
          next(`/login?redirect=${to.path}`);
        }
      }
    }
  } else {
    if (
      (to.meta && to.meta.isLogin === false) ||
      (to.matched[0].meta && to.matched[0].meta.isLogin === false)
    ) {
      // in the free login whitelist, go directly
      next();
    } else {
      // next()
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.fullPath}`);
    }
  }
});

router.afterEach((to) => {
  // 设置页面标题
  getTitle(to);
  // document.title = to.meta.title
  // // 设置页面滚动条位置
  // window.scrollTo(0, 0)
});
