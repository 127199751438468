import { getRawMaterialData, getbatchList } from "@/api/sellerCenter";
const batch = {
    state: {
        batchUpload: [],
        total:0,
        categoryIds: []
    },
    mutations: {
        SET_BATCH(state, data) {
            state.batchUpload = data;
            state.total = data.length || 0;
        },
        SET_CATEGROYIDS(state, data) {
            state.categoryIds = data
        }
    },
    actions: {
        getRawMaterialData({ commit }, formData) {
            return new Promise((resolve, reject) => {
                getRawMaterialData(formData).then(res => {
                    if (res.data.code == 0) {
                        if (res.data.data.length > 0) {
                            commit('SET_BATCH', res.data.data);
                            resolve(res)
                        } else {
                            reject('请重新上传');
                        }
                    } else {
                        reject(res.data.message);
                    }
                }).catch(error => {
                    reject(error);
                })
            })
        },
        getBatchList({ commit }, data) {
            return new Promise((resolve, reject) => {
                getbatchList(data).then((res) => {
                    if (res.data.code === 0) {
                        commit('SET_CATEGROYIDS', res.data.data);
                        resolve();
                    } else {
                        reject(res.data.message)
                    }
                });
            })
        }

    }
}
export default batch