<template>
  <div class="goods-classify" :style="{ fontSize: `${font}px` }">
    <i
      class="el-icon-arrow-left arrow-left"
      @click="imgLeft()"
      v-if="font !== 14"
    ></i>
    <div
      class="items-warpper"
      :style="{
        marginLeft: font != 14 ? `50px` : 0,
        marginRight: font != 14 ? `50px` : 0,
      }"
    >
      <div class="items-goods">
        <div
          class="goods-imgs"
          :class="{ actived: currentIndex === null }"
          @click="getTableData({}, null)"
          :style="imgStyle"
        >
          <!-- <img :src="item.icon" alt="" /> -->
          <div>全部分类</div>
        </div>
      </div>
      <div class="items-goods" v-for="(item, index) in classify" :key="item.id">
        <div
          class="goods-imgs"
          :class="{ actived: currentIndex === index }"
          @click="getTableData(item, index)"
          :style="imgStyle"
        >
          <img v-if="item.icon" :src="item.icon" alt="" />
          <div>{{ item.alias ? item.alias : item.name }}</div>
        </div>
      </div>
    </div>
    <i
      v-if="font !== 14"
      class="el-icon-arrow-right arrow-right"
      @click="imgRight()"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    classify: {
      type: Array,
      default: function () {
        return [];
      },
    },
    getPage: {
      type: Function,
    },
    currentIndex: {
      type: Number,
      default: function () {
        return null;
      },
    },
    font: {
      type: Number,
      default: function () {
        return 38;
      },
    },
  },
  computed: {
    imgStyle() {
      return {
        transform: `translate3d(${this.chartsDistance}px, 0, 0)`, // 计算移动的距离(x,y,z)
      };
    },
  },
  data() {
    return {
      imgActiveIndex: 0, // 当前移动图片的索引值
      chartsDistance: 0, // 移动的距离
      allDistance: 0, // 总移动距离
    };
  },
  methods: {
    getTableData(item, index) {
      this.$emit("getPage", item?.id, index);
    },
    imgLeft() {
      if (this.imgActiveIndex > 0) {
        this.imgActiveIndex--;
        var index = 0;
        const temp = window.setInterval(() => {
          // 利用定时器实现图片左右移动的动画效果
          if (index < 66) {
            // 移动次数(33次)
            this.chartsDistance += 3; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
            index++;
            return;
          } else {
            window.clearInterval(temp); // 移动完清除定时器
          }
        }, 5);
      }
    },
    imgRight() {
      if (this.imgActiveIndex < this.classify.length - 1) {
        this.imgActiveIndex++;
      }
      if (this.imgActiveIndex <= this.classify.length - 7) {
        let index = 0;
        const temp = window.setInterval(() => {
          if (index < 66) {
            this.chartsDistance -= 3; // 每次向右移动的距离
            index++;
            return;
          } else {
            window.clearInterval(temp);
          }
        }, 5);
      } else if (this.imgActiveIndex > this.classify.length - 7) {
        if (this.classify.length - 7 > 0) {
          this.imgActiveIndex = this.classify.length - 7;
        } else {
          this.imgActiveIndex = 0;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-classify {
  margin-bottom: 17px;
  position: relative;
  width: 100%;
  .items-warpper {
    display: flex;
    position: relative;
    margin: 0;
    padding: 0;
    width: calc(100% - 100px);
    height: 38px;
    line-height: 38px;
    overflow: hidden;
    .goods-imgs {
      height: 38px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 15px;
      margin-right: 10px;
      box-sizing: border-box;
      padding: 10px 14px 8px 14px;
      border-radius: 19px;
      white-space: nowrap;
      img{
        width: 20px;
        height: 20px;
      }
      &.actived {
        background: #c4ecff;
      }
    }
  }
}
.arrow-left {
  height: 38px;
  width: 50px;
  line-height: 38px;
  font-size: 38px;
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
}
.arrow-right {
  height: 38px;
  width: 50px;
  line-height: 38px;
  font-size: 38px;
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  text-align: center;
}
.colf8 {
  color: #dcdfe6;
}
</style>
