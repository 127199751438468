export default [
  {
    path: '/shop/:id',
    name: 'shop',
    meta: {
      isLogin: false
    },
    component: () => import('@/views/shop/ShopHome.vue')
  },
  {
    path: '/shop/:id/goods',
    name: 'goods',
    meta: {
      isLogin: false
    },
    component: () => import('@/views/shop/ShopGoods.vue')
  },
  {
    path: '/shoppingcart',
    name: 'shoppingcart',
    component: () => import('@/views/shop/ShoppingCart.vue'),
    meta: {
      needbuyer: true
    },
    // component: () => import('@/views/shop/testCart.vue')
  },
  {
    path: '/shop/:id/introduce',
    name: 'introduce',
    meta: {
      isLogin: false
    },
    component: () => import('@/views/shop/ShopIntroduce.vue')
  },
  {
    path: '/goods/details/:goodsId',
    name: 'goodsDetails',
    meta: {
      isLogin: false
    },
    component: () => import('@/views/shop/GoodsDetails.vue')
  },
]