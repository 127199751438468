export default [
  {
    path: '/404',
    meta: {
      isLogin: false,
      isShowHeader: true
    },
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/500',

    meta: {
      isLogin: false,
      isShowHeader: true
    },
    component: () => import('@/views/error-page/500'),
    hidden: true
  }
]