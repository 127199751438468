<!-- 企业展位组件 -->
<template>
  <div @click="jump(obj)">
    <div class="item">
      <div class="company-warp">
        <el-image
          :src="obj.mediaUrl || (obj.introduce && obj.introduce.images?.url)"
        >
          <div slot="error" class="image-slot">
            {{ obj.name.replace("有限公司", "") }}
          </div>
        </el-image>
        <div class="company-name">{{ obj.name }}</div>
      </div>
    </div>
    <div class="company-classify">{{ obj.tips }}</div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default() {
        return {
          name: "公司名称",
          mediaUrl: "",
        };
      },
    },
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    jump(item) {
      if (!item.linkUrl) return false;
      if (item.type == 1) {
        this.$router.push(item.linkUrl);
      } else {
        window.open(item.linkUrl);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  padding-left: 40px;
  width: 252px;
  display: flex;
  flex-direction: column;
}
.company-warp {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  .company-name {
    width: 110px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
}
.company-classify {
  width: 170px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #b3b3b3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  text-align: center;
}
.el-image {
  margin-right: 13px;
  width: 46px;
  height: 46px;
  ::v-deep .image-slot {
    box-sizing: border-box;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    background: #7eb2d8;
    color: #ffffff;
    width: 100%;
    height: 100%;
    font-size: 12px;
  }
}
</style>
