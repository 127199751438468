
  // 页面长时间失活刷新页面
  let time = 0
  let timer = null
  document.addEventListener("visibilitychange", ()=>{
    if(document.visibilityState === "hidden"){
        console.log('hidden');
        timer = setInterval(()=>{
          time++
        }, 1000)
    }else if(document.visibilityState === "visible"){
        console.log("visible");
        if(time > 60 * 5){
          location.reload()
        }
        time = 0
        clearInterval(timer)
        timer = null
    }
  });
