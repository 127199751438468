export default {
  path: '/finance',
  name: 'Finance',
  redirect: '/finance/index',
  component: () => import('@/views/finance/index.vue'),
  children: [
    {
      path: '/finance/index',
      name: 'FinanceHome',
      meta: {
        title: '首页'
      },
      component: () => import('@/views/finance/home/index.vue'),
    },
    {
      path: '/finance/account',
      meta: {
        title: '账户信息'
      },
      redirect: '/finance/account/info',
      component: () => import('@/views/finance/empty.vue'),
      children: [
        {
          path: '/finance/account/info',
          name: 'FinanceAccount',
          meta: {
            title: '账户信息'
          },
          component: () => import('@/views/finance/account-info/index.vue'),
        },
        {
          path: '/finance/account/agreement',
          name: 'FinanceAgreement',
          meta: {
            title: '协议'
          },
          component: () => import('@/views/finance/account-info/agreement.vue'),
        },
        {
          path: '/finance/account/further-information',
          name: 'FinanceAccountInfo',
          meta: {
            title: '开户资料'
          },
          component: () => import('@/views/finance/account-info/further-information.vue'),
        },
        {
          path: '/finance/account/account-status',
          name: 'FinanceAccountOpenStatus',
          meta: {
            title: '账户开通'
          },
          component: () => import('@/views/finance/account-info/accountOpenStatus.vue'),
        },
        {
          path: '/finance/account/password',
          name: 'FinanceAccountPassword',
          meta: {
            title: '账户开通'
          },
          component: () => import('@/views/finance/account-info/setPassword.vue'),
        },
        {
          path: '/finance/account/log-off',
          name: 'LogOff',
          meta: {
            title: '注销'
          },
          component: () => import('@/views/finance/account-info/logOff.vue'),
        },
        // 绑卡
        {
          path: '/finance/cardBinding/add',
          name: 'FinanceCardBindingAdd',
          meta: {
            title: '绑卡'
          },
          component: () => import('@/views/finance/cardBinding/index.vue'),
        },
        // 校验金验证
        {
          path: '/finance/cardBinding/audit',
          name: 'FinanceCardBindingAudit',
          meta: {
            title: '校验金审核'
          },
          component: () => import('@/views/finance/cardBinding/result.vue'),
        },
        {
          path: '/finance/cardBinding/check-money',
          name: 'FinanceCardBindingCheckMoney',
          meta: {
            title: '校验金验证'
          },
          component: () => import('@/views/finance/cardBinding/checkMoney.vue'),
        },
        // 绑卡
        {
          path: '/finance/bank/add',
          name: 'bankAdd',
          meta: {
            title: '添加银行卡'
          },
          component: () => import('@/views/finance/cardBinding/addBankCard.vue'),
        },
        // 卡详情页
        {
          path: '/finance/bank/:id',
          name: 'bankDetail',
          meta: {
            title: '银行账户'
          },
          component: () => import('@/views/finance/bank-card/index.vue'),
        },
      ]
    },
    {
      path: '/finance/trading-record',
      meta: {
        title: '交易记录'
      },
      redirect: '/finance/trading-record/index',
      component: () => import('@/views/finance/empty.vue'),
      children: [{
        path: '/finance/trading-record/index',
        name: 'FinanceTradingRecord',
        meta: {
          title: '交易记录'
        },
        component: () => import('@/views/finance/trading-record/index.vue'),
      }, {
        path: '/finance/trading-record/transactionDetail',
        name: 'TransactionDetail',
        meta: {
          title: '交易详情'
        },
        component: () => import('@/views/finance/trading-record/transactionDetail.vue'),
      },{
        path: '/finance/trading-record/refundDetail',
        name: 'TransactionDetail',
        meta: {
          title: '退款详情'
        },
        component: () => import('@/views/finance/trading-record/refundDetail.vue'),
      }]
    },
    {
      path: '/finance/account-assets',
      meta: {
        title: '账户资产',
        isHide: true
      },
      redirect: '/finance/account/info',
      component: () => import('@/views/finance/empty.vue'),
      children: [
        {
          path: '/finance/account-assets/info',
          name: 'FinanceAccountAssets',
          meta: {
            title: '账户资产'
          },
          component: () => import('@/views/finance/account-assets/index.vue'),
        },
        {
          path: '/finance/account-assets/top-up',
          name: 'FinanceAccountTopUp',
          meta: {
            title: '充值'
          },
          component: () => import('@/views/finance/top-up/index.vue'),
        },
        {
          path: '/finance/account-assets/withdrawal',
          name: 'Withdrawal',
          meta: {
            title: '提现'
          },
          component: () => import('@/views/finance/account-assets/withdrawal.vue'),
        },
        {
          path: '/finance/account-assets/is-top-up',
          name: 'FinanceAccountIsTopUp',
          meta: {
            title: '充值'
          },
          component: () => import('@/views/finance/top-up/isTopup.vue'),
        },
        {
          path: '/finance/account-assets/result',
          name: 'FinanceAccountResult',
          meta: {
            title: '充值'
          },
          component: () => import('@/views/finance/top-up/result.vue'),
        },
        {
          path: '/finance/account-assets/transfer-accounts',
          name: 'FinanceTransferAccounts',
          meta: {
            title: '转账'
          },
          component: () => import('@/views/finance/TransferAccounts'),
        }
      ]
    },
    {
      path: '/finance/account-set',
      name: 'FinanceAssets',
      meta: {
        title: '账户设置'
      },
      redirect: '/finance/account-set/index',
      component: () =>  import('@/views/finance/empty.vue'),
      children: [
        {
          path: '/finance/account-set/index',
          name: 'FinanceAccountSetInfo',
          meta: {
            title: '账户设置'
          },
          component: () => import('@/views/finance/account-set/index.vue'),
        },
        {
          path: '/finance/account-set/password',
          name: 'FinanceAccountSetPassword',
          meta: {
            title: '修改支付密码'
          },
          component: () => import('@/views/finance/account-set/password.vue'),
        },
        {
          path: '/finance/account-set/reset-password',
          name: 'FinanceAccountSetResetPassword',
          meta: {
            title: '重置支付密码'
          },
          component: () => import('@/views/finance/account-set/resetPassword.vue'),
        },
        {
          path: '/finance/account-set/phone',
          name: 'FinanceAccountSetPhone',
          meta: {
            title: '修改手机号'
          },
          component: () => import('@/views/finance/account-set/phone.vue'),
        },
        {
          path: '/finance/account-set/agent',
          name: 'FinanceAccountAgent',
          meta: {
            title: '修改经办人'
          },
          component: () => import('@/views/finance/account-set/agent.vue'),
        }
      ]
    }
  ]
}