
import axios from '@/utils/http'
import config from '@/config/index'
let {POWER_CABLE_BASE}=config;

export const uploadUrl = `${POWER_CABLE_BASE}/upload/file`
export function getCode(type, params) {
  return axios({
    url: `${config.baseUrl}/code/${type}`,
    method: 'get',
    params
  })
}

export function upload( data) {
  return axios({
    url: `${POWER_CABLE_BASE}/upload/file`,
    method: 'post',
    data
  })
}

// 行政区划数据树
export function getRegionTree(level) {
  return axios({
    url: `${POWER_CABLE_BASE}/region/tree/${level}`,
    method: 'GET'
  })
}

// 查询价格走势
export function getPriceTrend(data) {
  return axios({
    url: `${config.baseUrl}/priceTrend/trend`,
    method: 'GET',
    params:data
  })
}
//查询历史价格数据
export function getHistoryList(category){
    return axios({
      url:`${config.baseUrl}/priceTrend/getHistoricalData?category=${category}`,
      method: 'GET'
    })
}
//获取实时价格
export function getRealPrice(category){
  return axios({
    url:`${config.baseUrl}/priceTrend/getRealPrice?category=${category}`,
      method: 'GET'
  })
}

// 身份证识别（图片链接格式）
export function getOcrIdcardUrl(params) {
  return axios({
    url: `${POWER_CABLE_BASE}/ocr/idcard/url`,
    method: 'GET',
    params
  })
}
// 身份证识别（文件格式）
export function getOcrIdcardFile(data) {
  return axios({
    url: `${POWER_CABLE_BASE}/ocr/idcard/file`,
    method: 'post',
    data
  })
}

// 营业执照识别（图片链接格式）
export function getOcrLicenseUrl(params) {
  return axios({
    url: `${POWER_CABLE_BASE}/ocr/license/url`,
    method: 'GET',
    params
  })
}
// 营业执照识别（文件格式）
export function getOcrLicenseFile(data) {
  return axios({
    url: `${POWER_CABLE_BASE}/ocr/license/file`,
    method: 'post',
    data
  })
}

// 根据分类获取广告
export function getPoster(type) {
  return axios({
    url: `${config.baseUrl}/poster/${type}`,
    method: 'GET'
  })
}
//获取后端文件流，订单管理（导出）
export function downFile(params, url=`${config.baseUrl}/export/simple/order`){
    return axios({
      url: url,
      params,
      method: 'GET' ,
      responseType: 'blob'
    })
}

/**
 * 合同浏览页面
 * @param sn 合同编号
 * @returns {*}
 */
export function getContractViewurl(sn) {
  return axios({
    url: `${config.baseUrl}/contract/viewurl/${sn}`,
    method: 'GET'
  })
}

/**
 * 契约锁合同签署页面链接
 * @param sn 合同编码
 * @returns {*}
 */
export function getContractSignurl(sn) {
  return axios({
    url: `${config.baseUrl}/contract/signurl/${sn}`,
    method: 'GET'
  })
}

//设置签章负责人
export function setSignCharger(data){
  return axios({
    url:`${config.baseUrl}/seal/charger/set`,
    method:'post',
    data
  })
}

//获取签章负责人
export function getSealCharger(){
  return axios({
    url:`${config.baseUrl}/seal/charger/get`,
    method:'get',
  })
}

// 设置主营类目
export function saveMainBusiness(data){
  return axios({
    url:`${config.baseUrl}/company/update/main/category`,
    method:'put',
    data
  })
}
// 获取主营类目
export function getMainBusiness(params){
  return axios({
    url:`${config.baseUrl}/company/main/category`,
    method:'get',
    params
  })
}

// 添加用户访问记录
export function accessTouch(params){
  return axios({
    url:`${POWER_CABLE_BASE}/log/touch`,
    method:'get',
    params
  })
}
/*
export function accessTouch(params){
  return axios({
    url:`${config.baseUrl}/access/touch`,
    method:'get',
    params
  })
}
*/

// 待处理订单数
export function getWaitCount(params){
  return axios({
    url:`${config.baseUrl}/report/orderWatiHandle`,
    method:'get',
    params
  })
}
export function getAllCompany(){
  return axios({
    url:`${config.baseUrl}/companyCertify/getAllCompany`,
    method:'get'
  })
}
