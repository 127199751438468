<template>
  <div class="company-show">
    <div class="hot-title">
      <div class="hot-show">企业展示</div>
      <div class="more-hot" @click="jump('companyList')">
        更多 <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="hot-wrap">
      <company-item
        class="hot-item"
        v-for="item in companyList"
        :key="item.id"
        :obj="item"
      ></company-item>
    </div>
  </div>
</template>

<script>
import CompanyItem from "@/components/company/CompanyItem.vue";
import { getBoothHot } from "@/api/company";
export default {
  components: {
    CompanyItem,
  },
  data() {
    return {
      companyList: [],
    };
  },
  mounted() {
    this.getBoothHot();
  },
  methods: {
    getBoothHot() {
      getBoothHot({ currentPage: 1, pageSize: 100 }).then((res) => {
        if (res.data.code == 0) {
          this.companyList = res.data.data.records
            .filter((item) => item.type == "2")
            ?.slice(0, 12);
          this.partnerList = res.data.data.records
            .filter((item) => item.type == "1")
            ?.slice(0, 12);
        }
      });
    },
    jump(name) {
      this.$router.push({
        name: name,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.company-show {
  height: 348px;
  width: 1200px;
  margin: 10px auto;
  display: flex;
  align-items: center;

  .hot-title {
    background-image: url("@/assets/images/home/hot.png");
    background-size: 100% 100%;
    box-sizing: border-box;
    width: 160px;
    height: 100%;
    padding-top: 17px;
    padding-left: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    .more-hot {
      margin-top: 15px;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .hot-wrap {
    width: 1040px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .hot-item {
      box-sizing: border-box;
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 252px;
      height: 109px;
      background: #ffffff;
      border-radius: 4px;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover{
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      }
    }
  }
}
</style>
