<template>
  <div class="container">
    <el-image :src="require('@/assets/images/home/msg.png')" />
    <vue-seamless-scroll
      :data="list"
      class="warp"
      :class-option="classOption"
    >
    <ul class="ul-item">
      <li class="li-item" v-for="(item, index) in list" :key="index">
        欢迎{{ item.name }}入驻
      </li>
    </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import { getCompanyAuthList } from '@/api/company'
export default {
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      list: [],
      classOption: {
        limitMoveNum: 2,
        direction: 2,
        hoverStop: false
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getCompanyAuthList({size: 20}).then(res => {
        if (res.data.code == 0) {
          this.list = res.data.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
ul,li{
  margin: 0;
  padding: 0;
  list-style: none;
}
.container{
  box-sizing: border-box;
  padding: 15px 20px;
  width: 1200px;
  margin: 10px auto;
  background: white;
  display: flex;
  overflow: hidden;
  align-items: center;
  .el-image{
    margin-right: 40px;
    flex-shrink: 0;
    width: 22px;
  }
  .warp{
    overflow: hidden;
    ul {
      list-style: none;
      padding: 0;
      &.ul-item {
        display: flex;
        align-content: center;
        .li-item {
          margin-right: 50px;
          font-size: 14px;
          color: #333333;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>