<template>
  <div>
  <el-badge v-if="badge" :value="badge">
    <router-link
      v-bind="$attrs"
      :to="$attrs.to"
      custom
      v-slot="{ navigate , isActive }"
      :exact="$attrs.exact"
    >
      <component
        :is="$attrs.tag"
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
         :class="{active: $attrs['active-class'] === 'active' ? isActive : ''}"
      >
        <a>{{ $attrs.text }}</a>
      </component>
    </router-link>
  </el-badge>
  <router-link
      v-else
      v-bind="$attrs"
      :to="$attrs.to"
      custom
      v-slot="{ navigate , isActive }"
      :exact="$attrs.exact"
    >
      <component
        :is="$attrs.tag"
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
         :class="{active: $attrs['active-class'] === 'active' ? isActive : ''}"
      >
        <a>{{ $attrs.text }}</a>
      </component>
    </router-link>
  </div>
</template>
<script>
export default{
  props: {
    badge: {
      type: Number || String,
      default: 0
    }
  }
}
</script>
