<template>
  <div class="login">
    <login-header></login-header>
    <div class="container">
      <div class="register-wrap">
        <div class="title">找回密码-<span>手机号找回</span></div>
        <div class="form-wrap">
          <el-form
            ref="ruleForm"
            :rules="rules"
            label-width="120px"
            :model="formRegister"
            class="ruleForm"
          >
            <el-form-item label="手机号" prop="mobile">
              <el-input
                v-model="formRegister.mobile"
                prefix-icon="el-icon-user"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <input type="text" style="height: 0; border: 0; padding: 0" />
            <input type="password" style="height: 0; border: 0; padding: 0" />
            <el-form-item label="动态验证码" prop="code">
              <el-input
                class="verificationCode"
                v-model="formRegister.code"
                placeholder="请输入动态验证码"
              ></el-input>
              <el-button
                type="primary"
                class="get-code-button"
                @click="countDown('ruleForm')"
                :disabled="!canClick"
                >{{ content }}</el-button
              >
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                v-model="formRegister.password"
                placeholder="密码"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPwd">
              <el-input
                v-model="formRegister.confirmPwd"
                placeholder="确认密码"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSubmit('ruleForm')"
                >确认重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <el-divider></el-divider>
        <div class="read">
          <div>
            <span>已有账号</span>
            <router-link class="go-login" to="login"> 去登录 </router-link>
          </div>
        </div>
      </div>
    </div>
    <FooterLogin></FooterLogin>
    <el-dialog :visible.sync="dialogVisible" width="30%" :cell-style="{ 'text-align': 'center' }">
      <span>当前手机号下绑定有多个企业，请选择本次登录的账号</span>
      <el-table :data="tableData" height="300px" style="width: 100%">
        <el-table-column label="选择" width="55" align="center">
          <template slot-scope="{ row }">
            <el-radio v-model="acctId" :label="row.id">{{ "" }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="公司名称" width="180">
        </el-table-column>
        <el-table-column prop="nickName" label="账号昵称" width="180">
        </el-table-column>
        <el-table-column prop="acct" label="账号"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updetaMobile">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LoginHeader from "@/components/header/LoginHeader.vue";
import FooterLogin from "@/components/FooterLogin.vue";
import { getCode } from "@/api/api";
import { RetrievePassword } from "@/api/user";
export default {
  name: "LoginView",
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formRegister.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      formRegister: {
        mobile: "",
        password: "",
        confirmPwd: "",
        code: "",
      },
      tableData: [],
      dialogVisible: false,
      acctId: "",
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: this.$rules.checkPhone, trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入动态验证码", trigger: "blur" },
          { validator: this.$rules.checkSmsCode, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: this.$rules.checkPassword, trigger: "blur" },
        ],
        confirmPwd: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      content: "获取动态码",
      totalTime: null,
      canClick: true, //添加canClick
    };
  },
  components: {
    LoginHeader,
    FooterLogin,
  },
  methods: {
    getCode() {
      let params = {
        mobile: this.formRegister.mobile,
        useType: "SMS_273075109",
      };
      return new Promise((resolve,reject) =>{
        getCode("sim/password/retrieve", params).then(({ data }) => {
          if (data.code == 0) {
            console.log("获取成功");
            resolve(data)
          }
        });
      })
    },
    countDown(formName) {
      this.$refs[formName].validateField("mobile", async(errorMessage) => {
        if (!errorMessage) {
          if (!this.canClick) return; //改动的是这两行代码
          let res = await this.getCode();
          this.totalTime = res.data.property.reSendIn;
          this.canClick = false;
          // 接口调用
          this.content = this.totalTime + "s后重新获取"; //这里解决60秒不见了的问题
          let clock = window.setInterval(() => {
            this.totalTime--;
            this.content = this.totalTime + "s后重新获取";
            if (this.totalTime < 0) {
              //当倒计时小于0时清除定时器
              window.clearInterval(clock);
              this.content = "重新获取动态码";
              this.totalTime = 60;
              this.canClick = true; //这里重新开启
            }
          }, 1000);
        }
      });
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 接口提交
          let data = {
            ...this.formRegister,
          };
          this.retriew(data);
        }
      });
    },
    updetaMobile() {
      if(this.acctId == ''){
        return this.$message.error('请选择需要找回的手机号码！')
      }
      let data = {
        ...this.formRegister,
        id: this.acctId,
      };
      this.retriew(data);
    },
    retriew(data) {
      RetrievePassword(data).then((res) => {
        if (res.data.code == 0) {
          if (Array.isArray(res.data.data) && res.data.data.length) {
            this.tableData = res.data.data;
            this.dialogVisible = true;
          } else {
            this.$message.success('操作成功！')
            this.jump("login");
          }
        }
      });
    },
    jump(name) {
      this.$router.push({
        name: name,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-wrap ::v-deep .el-input__inner {
  height: 60px;
}
.form-wrap ::v-deep .el-form-item__label {
  line-height: 60px;
  padding-right: 19px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #87909d;
}
.container {
  box-sizing: border-box;
  padding-top: 129px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding-bottom: 60px;
  background-image: url("@/assets/images/login-background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .title {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #4f5a6b;
    line-height: 24px;
    &::before {
      margin-right: 10px;
      content: "";
      display: block;
      width: 4px;
      height: 18px;
      background: #e60012;
    }
    span {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #4f5a6b;
      line-height: 24px;
    }
  }
}
.register-wrap {
  box-sizing: border-box;
  padding: 44px 60px;
  width: 960px;
  background: #ffffff;
  box-shadow: 0px 10px 10px 0px rgba(79, 114, 237, 0.05);
  border-radius: 16px;
  .form-wrap {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .el-button {
    width: 400px;
    height: 60px;
    font-size: 18px;
  }
  .el-input {
    width: 400px;
  }
  .verificationCode {
    width: 250px;
  }
  .get-code-button {
    margin-left: 20px;
    width: 130px;
    vertical-align: bottom;
    font-size: 14px;
    font-family: Source Han Sans CN;
    // background: #e60012;
    // color: #ffffff;
    font-weight: 400;
  }
  .read {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #87909d;
    line-height: 24px;
    a {
      color: #e60012;
    }
  }
}
a {
  text-decoration: none;
  color: #cacdd5;
}
</style>
