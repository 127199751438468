export default {
  path: '/center',
  name: 'PersonalCenter',
  redirect: '/center/account',
  meta: {
    isLogin: true,
    title: '个人中心'
  },
  component: () => import('@/views/center/index.vue'),
  children: [
    {
      path: '/center/account',
      name: 'AccountInfo',
      meta: {
        title: '账号信息管理'
      },
      component: () => import('@/views/center/account/index.vue'),
    },
    {
      path: '/center/notice',
      name: 'Notice',
      meta: {
        title: '企业公告管理'
      },
      redirect: '/center/notice/list',
      component: () => import('@/views/center/empty.vue'),
      children: [
        {
          path: 'list',
          name: 'NoticeList',
          meta: {
            title: '企业公告管理'
          },
          component: () => import('@/views/center/notice/list.vue'),
        },
        {
          path: 'add',
          name: 'NoticeAdd',
          meta: {
            title: '企业公告管理'
          },
          component: () => import('@/views/center/notice/index.vue'),
        }
      ]
    },
    {
      path: '/center/company',
      meta: {
        title: '企业信息管理'
      },
      component: () => import('@/views/center/empty.vue'),
      children: [
        {
          path: '',
          name: 'Company',
          meta: {
            title: '企业信息'
          },
          component: () => import('@/views/center/company/index.vue'),
        },
        {
          path: '/center/company/CertificationRecord',
          name: 'CertificationRecord',
          meta: {
            title: '认证记录'
          },
          component: () => import('@/views/center/CertificationRecord/index.vue'),
        }
      ]
    },
    {
      path: '/center/yellow-pages',
      name: 'YellowPages',
      meta: {
        title: '企业黄页管理'
      },
      component: () => import('@/views/center/YellowPages/index.vue'),
    }
  ]
}