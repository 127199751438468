<template>
  <div class="price-container">
    <div class="echarts-box">
      <div class="trend-title">大盘走势</div>
      <echartsTab
        ref="echartsTab"
        @getHistoryData="getHistoryData"
        :price="realPrice"
      ></echartsTab>
    </div>
    <div class="history-price">
      <div class="history-title">
        历史价格<span>（{{ categoryName }}）</span>
      </div>
      <div class="table-price">
        <div class="table-head">
          <div class="date">日期</div>
          <div class="min">最低价</div>
          <div class="max">最高价</div>
          <div class="average">日均价</div>
          <div class="rise">涨幅</div>
        </div>
        <div class="every">
          <div class="date">总均价</div>
          <div class="min">{{ historyMinPrice }}</div>
          <div class="max">{{ historyMaxPrice }}</div>
          <div class="average">{{ historyAveragePrice }}</div>
          <div class="rise">{{ "-" }}</div>
        </div>
        <div class="every" v-for="el in itemHistory" :key="el.id">
          <div class="date" style="cursor: pointer" @click="selectSomeTime(el)">
            {{ el.updateTime.substring(0, 10) }}
          </div>
          <div class="min">{{ el.minPrice }}</div>
          <div class="max">{{ el.maxPrice }}</div>
          <div class="average">{{ el.averagePrice }}</div>
          <div class="rise" :class="el.subtraction > 0 ? 'down' : 'up'">
            {{
              el.subtraction > 0
                ? "-" + el.subtraction
                : "+" + Math.abs(el.subtraction)
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getHistoryList, getRealPrice } from "@/api/api";
import echartsTab from "@/components/echartsTab.vue";
export default {
  components: { echartsTab },
  data() {
    return {
      realPrice: null,
      categoryName: '沪铜',
      historyMinPrice: 0,
      historyMaxPrice: 0,
      historyAveragePrice: 0,
      itemHistory: [],
    }
  },
  mounted() {
    this.getHistoryList("CU");
    this.getRealPrice("CU");
  },
  methods: {
    getHistoryData(category, categoryName) {
      this.categoryName = categoryName
      this.getHistoryList(category);
      this.getRealPrice(category);
      // this.computedHistory(this.trendHistoryList[category]);
    },
    getHistoryList(category) {
      getHistoryList(category).then((res) => {
        if (res.data.code == 0) {
          this.trendHistoryList = res.data.data;
          this.computedHistory(this.trendHistoryList);
        }
      });
    },
    getRealPrice(category) {
      getRealPrice(category).then((res) => {
        if (res.data.code == 0) {
          this.realPrice = res.data.data;
        }
      });
    },
    selectSomeTime(el){
      if (this.categoryName == '电解铜') return false;
      this.$refs.echartsTab.begin = this.$moment(el.updateTime).startOf('day').format("YYYY-MM-DD HH:mm:ss");
      this.$refs.echartsTab.end = this.$moment(el.updateTime).startOf('day').add(1, "days").format("YYYY-MM-DD HH:mm:ss");
      this.$refs.echartsTab.type = 'hh:mm'
      this.$refs.echartsTab.trendCurrent = 0
      this.$refs.echartsTab.day = this.$moment(el.updateTime).format("YYYY-MM-DD")
      this.$refs.echartsTab.getPriceTrend();
    },
    computedHistory(list) {
      let data = list
        .slice(0, 10)
        .reverse()
        .map((item) => item.averagePrice);
      //遍历取值
      // console.log(list.slice(0, 9));
      this.historyMinPrice = (
        list.slice(0, 9).reduce((prev, curr) => {
          return prev + curr.minPrice;
        }, 0) / 9
      ).toFixed(2);
      this.historyMaxPrice = (
        list.slice(0, 9).reduce((prev, curr) => {
          return prev + curr.maxPrice;
        }, 0) / 9
      ).toFixed(2);
      this.historyAveragePrice = (
        list.slice(0, 9).reduce((prev, curr) => {
          return prev + curr.averagePrice;
        }, 0) / 9
      ).toFixed(2);
      let arr = [];
      for (let i = 0; i < data.length - 1; i++) {
        arr.push((data[i] - data[i + 1]).toFixed(2));
      }
      this.itemHistory = list
        .slice(0, 9)
        .reverse()
        .map((item, index) => ({
          subtraction: arr[index],
          ...item,
        }))
        .reverse();
    },
  }
}
</script>
<style lang="scss" scoped>
.price-container {
  width: 1200px;
  margin: 10px auto;
  height: 476px;
  display: flex;
  align-items: center;
  .echarts-box {
    padding: 20px 22px;
    box-sizing: border-box;
    background: #fff;
    width: 710px;
    height: 100%;
    .trend-title {
      text-align: left;
      position: relative;
      margin-bottom: 16px;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: #333333;
    }
  }
  .history-price {
    width: calc(100% - 710px);
    height: 100%;
    margin-left: 13px;
    background: #fff;
    box-sizing: border-box;
    .history-title {
      text-align: left;
      position: relative;
      margin-bottom: 5px;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: #333333;
      margin-top: 20px;
      margin-left: 22px;
      span {
        font-size: 16px;
        font-weight: 400;
        color: #e60012;
      }
    }
    .table-price {
      height: calc(100% - 58px);
      width: 100%;
      .table-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-image: linear-gradient(#f6fcff, #fafdff);
        height: 40px;
        padding: 0 10px;
        box-sizing: border-box;
        .date {
          width: 117px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #333;
        }
        .min {
          width: 90px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #999;
        }
        .max {
          width: 90px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #999;
        }
        .average {
          width: 90px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #999;
        }
        .rise {
          width: 90px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #999;
        }
      }
      .every {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 38px;
        border-bottom: 1px solid #f5f5f5;
        padding: 0 10px;
        box-sizing: border-box;
        .date {
          width: 117px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          color: #333;
        }
        .min {
          width: 90px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          color: #999;
        }
        .max {
          width: 90px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          color: #999;
        }
        .average {
          width: 90px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          color: #999;
        }
        .rise {
          width: 90px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          color: #999;
        }
      }
      .every:last-child {
        border-bottom: 0px;
      }
    }
  }
}
.up {
  color: #ff6c78 !important;
}
.down {
  color: #3ce643 !important;
}
</style>
