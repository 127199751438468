<template>
  <div class="news-list-container">
    <div class="deal">
      <div class="deal-title">成交动态</div>
      <div class="real-scroll">
        <vue-seamless-scroll
          v-if="tempArray.length >= 1"
          :data="tempArray"
          class="seamless-warp"
          :class-option="defaultOption"
        >
          <div class="ul-scoll">
            <div v-for="(item, index) in tempArray" :key="index">
              <div class="item-shop">
                <div class="strike">
                  <div class="buyer-company" :title="item.buyerName">
                    {{ item.buyerName }}
                  </div>
                  <div class="deal-name" :title="item.name">
                    {{ item.name }}
                  </div>
                  <div
                    class="deal-weight"
                    :title="`${item?.totalNum}${item?.unit}`"
                  >
                    {{ bigNumberTransform(item.totalNum) }}{{ item.unit }}
                  </div>
                  <div class="create-time">
                    {{ item.createTime.substring(10, item.createTime.length) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
    <div class="biding">
      <div class="bidding-title">
        <div>招标信息</div>
        <div class="more-bidding" @click="hanldeBidding(null)">
          更多 <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="bidding-content">
        <el-scrollbar class="scrollMenuBox" :native="false">
          <div
            class="bidding-detail"
            v-for="(item, index) in biddingList"
            :key="index"
            @click="hanldeBidding(item.jumpUrl)"
          >
            <div class="bidding-name">{{ item.title }}</div>
            <div class="bidding-time">{{ item.createTime }}</div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="news">
      <div class="news-title">
        <div class="article-title">
          <div
            class="news-type"
            v-for="(item, index) in articleList"
            :key="index"
            :class="{ newsActive: articleIndex == index }"
            @click="handleCurrentNews(item, index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="more-news" @click="jump('articleList')">
          更多 <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="news-content">
        <el-scrollbar class="scrollMenuBox" :native="false">
          <div
            class="news-detail"
            v-for="item in newsList"
            :key="item.id"
            @click="goNewsDetail(item)"
          >
            <div class="news-name">{{ item.name }}</div>
            <div class="news-time">
              {{ $moment(item.publishTime).format("YYYY-MM-DD") || "-" }}
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { bigNumberTransform } from "@/utils/tools";
import vueSeamlessScroll from "vue-seamless-scroll";
import { getRealData, getBiddingList } from "@/api/shop";
import { getArticleGroupList } from "@/api/article";
export default {
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      tempArray: [],
      biddingList: [],
      articleList: [],
      articleIndex: 0,
      newsList: [],
    }
  },
  computed: {
    defaultOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  mounted() {
    this.getRealData();
    this.getBiddingList();
    this.getArticleList()
  },
  methods: {
    getRealData() {
      getRealData().then((res) => {
        if (res.data.code === 0) {
          this.tempArray = res.data.data || [];
        }
      });
    },
    bigNumberTransform(str) {
      return bigNumberTransform(str);
    },
    hanldeBidding(jumpUrl) {
      if (!jumpUrl) return window.open("https://www.dlzb.com/zb/kw-dianlan.html");
      window.open(jumpUrl);
    },
    getBiddingList() {
      getBiddingList().then((res) => {
        if (res.data.code == 0) {
          if (res.data.data && res.data.data.length !== 0) {
            res.data.data.forEach((item) => {
              let index = item.createTime.lastIndexOf(" ");
              item.createTime = item.createTime.substring(index, -1);
            });
          }
          this.biddingList = res.data.data;
        }
      });
    },
    getArticleList() {
      getArticleGroupList({ size: 11 }).then((res) => {
        this.articleList = res.data.data;
        this.newsList = res.data.data[0].list;
      });
    },
    handleCurrentNews(item, index) {
      this.articleIndex = index;
      this.newsList = item.list;
    },
    goNewsDetail(item) {
      this.$router.push({
        name: "ArticlePage",
        params: { id: item.id },
      });
    },
    jump(name) {
      this.$router.push({
        name: name,
      });
    },
  }
};
</script>

<style scoped lang="scss">
.news-list-container {
  will-change: transform;
  margin: 12px 0;
  width: 1200px;
  height: 400px;
  display: flex;
  justify-content: space-between;
  .deal {
    height: 400px;
    width: 390px;
    overflow: hidden;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 600;
    color: #333333;
    background: #ffffff;
    .deal-title {
      height: 60px;
      line-height: 60px;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      background-image: url("@/assets/images/home/1.png");
      background-size: 100% 100%;
    }
    .real-scroll {
      padding: 0 21px;
      overflow: hidden;
      height: 330px;
      .real-status {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        margin-bottom: 20px;
      }
    }
  }
  .biding {
    height: 400px;
    width: 390px;
    overflow: hidden;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 600;
    color: #333333;
    background: #ffffff;
    .bidding-title {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      background-image: url("@/assets/images/home/2.png");
      background-size: 100% 100%;
      .more-bidding {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
      }
    }
    .bidding-content {
      padding: 0 0 0 21px;
      box-sizing: border-box;
      height: 330px;
      .scrollMenuBox {
        height: 330px;
        .bidding-detail {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 32px;
          padding-right: 20px;
          .bidding-name {
            width: 252px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            &:hover {
              color: #e60012;
            }
          }
          .bidding-time {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
  .news {
    height: 400px;
    width: 390px;
    overflow: hidden;
    background: #ffffff;
    .news-title {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      background-image: url("@/assets/images/home/3.png");
      background-size: 100% 100%;
      .article-title {
        display: flex;
        align-items: center;
        .news-type {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          margin-right: 20px;
          cursor: pointer;
          &.newsActive {
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 600;
            color: #333333;
          }
        }
      }

      .more-news {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
      }
    }
    .news-content {
      padding: 0 0 0 21px;
      height: 330px;
      box-sizing: border-box;
      .scrollMenuBox {
        height: 330px;
        .news-detail {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 32px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          padding-right: 20px;
          .news-name {
            width: 230px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            color: #333;
            cursor: pointer;
            &:hover{
              color: #e60012;
            }
          }
          .news-time {
            color: #999;
          }
        }
      }
    }
  }
}
.ul-scoll {
  .item-shop {
    // margin-top: 19px;
    margin-top: 10px;
    // height: 30px;
    margin-bottom: 10px;
    // line-height: 32px;
    .strike {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-bottom: 16px;
      // margin-bottom: 16px;
      .buyer-company {
        width: 109px;
        height: auto;
        margin-right: 10px;
      }
      .deal-name {
        margin-right: 10px;
        width: 70px;
        height: auto;
        white-space: wrap;
      }
      .deal-weight {
        width: 60px;
        height: auto;
        overflow: hidden;
        white-space: wrap;
        margin-right: 10px;
      }
      .create-time {
        color: #999 !important;
      }
    }
  }
}
</style>
