export default {
  path: '/message',
  name: 'Message',
  redirect: '/message/center/list',
  meta: {
    isLogin: true,
    title: '消息中心'
  },
  component: () => import('@/views/messageCenter/index.vue'),
  children: [
    {
      path: '/message/center',
      name: 'MessageCenter',
      redirect: '/message/center/list',
      meta: {
        title: '消息中心'
      },
      component: () => import('@/views/messageCenter/empty'),
      children: [
        {
          path: 'list',
          name: 'MessageList',
          meta: {
            title: '消息中心',
            keepAlive: true
          },
          component: () => import('@/views/messageCenter/message/index'),
        },
        {
          path: 'detail',
          name: 'MessageDetail',
          meta: {
            title: '消息中心'
          },
          component: () => import('@/views/messageCenter/message/detail'),
        },
      ]
    },
    
  ]
}