<template>
  <div
    class="chart-box"
    style="width: 100%; height: 336px; box-sizing: border-box; zoom: 1 / zoom"
    ref="chartBox"
  ></div>
</template>

<script>
import * as echarts from "echarts";
import { zoom } from "@/utils/getZoom";
export default {
  props: {
    echartData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    type: {
      type: String,
      default: 'hh.mm'
    }
  },
  mounted() {
    this.zoom = zoom();
    window.addEventListener("resize", () => {
      if (this.chart) {
        this.chart.resize();
      }
    });
  },
  data() {
    return {
      chart: null,
      zoom: "1",
      option: {
        // title: {
        //   text: "铜价走势",
        //   textStyle: {
        //     marginRight: "5%",
        //     marginLeft: "26%",
        //   },
        // },
        tooltip: {
          trigger: "axis",
          confine: true,
        },
        legend: {
          icon: "rect",
          bottom: "0px",
          data: [],
          itemHeight: 12,
          itemWidth: 12,
          textStyle: {
            //图例文字的样式
            fontSize: 13,
          },
        },
        grid: {
          top: "10%",
          left: "4%",
          right: "4%",
          bottom: "9%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          // inverse: true,
          boundaryGap: false,
          alignTicks:true,
          show: true,
          data: [],
          axisLabel: {
            formatter: function(value) {
              return echarts.format.formatTime('mm.hh', +new Date(value));
            }
          }
        },
        yAxis: {
          type: "value",
          scale: true,
        },
        series: [],
      },
      list: [],
    };
  },
  watch: {
    echartData: {
      handler() {
        this.getPriceTrend();
      },
      deep: true,
    },
  },
  methods: {
    getPriceTrend() {
      let series = [];
      let names = [];
      let xAxisData = [];
      this.echartData?.priceTrendList?.forEach((element) => {
        if (element?.priceTrendList.length === 0) return;
        let data = [];
        //获取任意项中的X轴时间
        // if (index == 0) {
        element.priceTrendList.forEach((item) => {
          let date = item.updateTime;
          xAxisData.push(date);
        });
        // }
        element.priceTrendList.forEach((item) => {
          data.push([item.updateTime, item.currentPrice]);
          //  data.push(item.currentPrice);
        });
        series.push({
          name: element.name,
          type: "line",
          smooth: true,
          showSymbol: false,
          data: data,
        });
        // });
        names.push(element.name);
      });
      this.option.series = series;
      // this.option.series.data = data;
      // 数组去重排序
      xAxisData = Array.from(new Set(xAxisData));
      xAxisData = xAxisData.sort((a, b) => {
        return this.$moment(a) - this.$moment(b);
      });
      this.option.legend.data = names;
      this.option.xAxis.data = xAxisData;
      this.initChart();
    },
    initChart() {
      this.$nextTick(() => {
        this.chart = echarts.getInstanceByDom(this.$refs.chartBox);
        if (this.chart == undefined) {
          this.chart = echarts.init(this.$refs.chartBox);
        }
        this.chart.clear();
        let option = { ...this.option };
        option.xAxis.axisLabel.formatter = (value) => {
          return echarts.format.formatTime(this.type, new Date(value));
        }
        this.chart.setOption(option);
      });
    },
  },
};
</script>
