<template>
  <div class="yl-carousel">
    <el-image
      :title="banners[0]?.tips"
      :style="{ height }"
      v-if="banners.length === 1"
      :src="banners[0]?.mediaUrl"
      @click="jump(banners[0]?.jumpUrl, banners[0]?.linkType)"
      fit="cover"
    ></el-image>
    <el-carousel :height="height" v-else>
      <el-carousel-item v-for="item in banners" :key="item.id">
        <div class="jump" @click="jump(item.jumpUrl, item.linkType)">
          <el-image :title="item.tips" :src="item.mediaUrl" fit="cover"></el-image>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { getPoster } from "@/api/api";
export default {
  data() {
    return {
      banners: [],
    };
  },
  props: {
    typeParams: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "380px",
    },
  },
  computed: {
    type() {
      let path = this.$route.meta.title;
      let type;
      switch (path) {
        case "首页":
          type = 1;
          break;
        case "易缆原料":
          type = 2;
          break;
        case "易缆商城":
          type = 3;
          break;
        case "易缆金融":
          type = 4;
          break;
        case "易缆物流":
          type = 5;
          break;
        case "易缆资讯":
          type = 6;
          break;
        case "易缆入驻":
          type = 7;
          break;
        case "其它":
          type = 8;
          break;
        case '电子账户':
          type = 9;
          break

        default:
          break;
      }
      return type;
    },
  },
  mounted() {
    this.getPoster();
  },
  methods: {
    jump(url, type) {
      if (type == 4) {
        window.open(url)
        return false
      }
      if (url) {
        this.$router.push(`${url}`);
      }
    },
    getPoster() {
      let type;
      if (this.typeParams) {
        type = this.typeParams;
      } else {
        type = this.type;
      }
      getPoster(type).then(({ data }) => {
        if (data.code == 0) {
          this.banners = data.data || [];
        }
      });
    },
  },
};
</script>

<style>
.el-image {
  height: 100%;
  width: 100%;
}
.el-carousel{
  will-change: transform;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.jump {
  cursor: pointer;
  height: 100%;
}
</style>
