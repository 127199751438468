<template>
  <div class="header">
    <div class="header-wrap">
      <div class="logo-box" @click="$router.push('/')">
        <div class="img-box">
          <img style="transform: rotate(45deg);" src="../../assets/images/logo/logo-left.gif" alt="" />
          <img src="../../assets/images/logo/logo-right.jpg" alt="" />
        </div>
        <!-- <div class="title">电缆贸易综合平台</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.header {
  box-sizing: border-box;
  padding: 12px 0;
  height: 60px;
  background: #ffffff;
  min-width: 1200px;
  .header-wrap {
    margin: 0 auto;
    width: 1200px;
    height: 100%;
    .logo-box {
      display: flex;
      align-items: center;
      cursor: pointer;
      .img-box {
        margin-right: 40px;
        display: flex;
        align-items: center;
        img {
          &:nth-child(1) {
            width: 30px;
            height: 30px;
          }
          &:nth-child(2) {
            width: 95px;
            height: 47px;
          }
        }
      }
      .title {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
    }
  }
}
</style>