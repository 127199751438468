
import RetrievePasswordPhone from '@/views/login/RetrievePasswordPhone.vue'
export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      title: '登录',
      isLogin: false,
      isShowHeader: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue'),
    meta: {
      title: '新用户注册',
      isLogin: false,
      isShowHeader: true
    }
  },
  {
    path: '/repsd',
    name: 'RetrievePassword',
    component: RetrievePasswordPhone,
    meta: {
      title: '找回密码',
      isLogin: false,
      isShowHeader: true
    }
  }
]