<template>
  <div class="partner">
    <div class="partner-title">
      <div class="partner-show">合作伙伴</div>
      <div class="more-partner" @click="jump('companyList')">
        更多 <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="partner-content">
      <div
        class="partner-enterprise-item"
        v-for="item in partnerList"
        :key="item.id"
      >
        <img
          @click="boothJump(item)"
          :src="item.mediaUrl || (item.introduce && item.introduce.images.url)"
          :title="item.tips"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getBoothHot } from "@/api/company";
export default {
  data() {
    return {
      partnerList: []
    }
  },
  mounted() {
    this.getBoothHot()
  },
  methods: {
    jump(name) {
      this.$router.push({
        name: name,
      });
    },
    boothJump(item) {
      if (item.linkType == 1) {
        this.$router.push(item.linkUrl);
      } else {
        window.open(item.linkUrl);
      }
    },
    getBoothHot() {
      getBoothHot({ currentPage: 1, pageSize: 100 }).then((res) => {
        if (res.data.code == 0) {
          this.companyList = res.data.data.records
            .filter((item) => item.type == "2")
            ?.slice(0, 12);
          this.partnerList = res.data.data.records
            .filter((item) => item.type == "1")
            ?.slice(0, 12);
        }
      });
    },
  }
};
</script>

<style scoped lang='scss'>
.partner {
  height: 348px;
  width: 1200px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  .partner-title {
    background-image: url("@/assets/images/home/partner.png");
    background-size: 100% 100%;
    box-sizing: border-box;
    width: 160px;
    height: 100%;
    padding-top: 17px;
    padding-left: 18px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    .partner-hot {
      margin-top: 15px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .more-partner {
    margin-top: 15px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .partner-content {
    width: 1040px;
    height: 348px;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    .partner-enterprise-item {
      width: 260px;
      height: 116px;
      cursor: pointer;
      img {
        width: 260px;
        height: 116px;
      }
    }
  }
}
</style>