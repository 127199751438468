import homeRoutes from './homeRoutes'
import sellerCenterRoutes from './sellerCenterRoutes'
import buyerCenterRoutes from './buyerCenterRoutes'
import personalCenterRoutes from './personalCenterRoutes'
import financeRoutes from './financeRoutes'
import messageRoutes from './messageRoutes'
import errorRoutes from './errorRoutes'
import LoginRoutes from './LoginRoutes'
import certificationRoutes from './certificationRoutes'
import userProtocolRoutes from './userProtocolRoutes'
import mallRoutes from './mallRoutes'
export const routes = [
  ...homeRoutes,
  ...LoginRoutes,
  ...mallRoutes,
  ...certificationRoutes,
  userProtocolRoutes,
  ...sellerCenterRoutes,
  ...buyerCenterRoutes,
  personalCenterRoutes,
  financeRoutes,
  messageRoutes,
  ...errorRoutes,
  { path: '*', redirect: '/404' }
]