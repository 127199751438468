import { getCartNum } from '@/api/shoppingCart'
const state = {
  cartNum: 0,
}
const mutations = {
  SET_CART_NUM(state, data) {
    state.cartNum = data
  }
}
const actions = {
  async getCartNum({ commit, rootGetters }) {
    if (rootGetters['user/isLogin']) {
      const res = await getCartNum()
      if (res.data.code === 0) {
        commit('SET_CART_NUM', res.data.data)
      }
    } else {
      commit('SET_CART_NUM', 0)
    }
  }
}
const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}