export default [
  {
    path: '/sellerCenter',
    component: () => import('@/views/sellerCenter/index.vue'),
    redirect: '/sellerCenter/goods/list',
    meta: {
      identity: 2,
      title: '销售管理'
    },
    children: [
      {
        path: '/sellerCenter/goods',
        component: () => import('@/views/sellerCenter/empty.vue'),
        redirect: '/sellerCenter/goods/list',
        meta: {
          title: '商品管理'
        },
        children: [
          {
            path: 'list',
            name: 'goodsList',
            meta: {
              keepAlive: true
            },
            component: () => import('@/views/sellerCenter/goodsManagement/goodsList.vue'),
          },
          {
            path: 'add',
            name: 'goodsAdd',
            component: () => import('@/views/sellerCenter/goodsManagement/goodsAdd.vue'),
          },
          {
            path: 'edit/:id',
            name: 'goodsEdit',
            component: () => import('@/views/sellerCenter/goodsManagement/goodsAdd.vue'),
          },
          {
            path: 'addBatch',
            name: 'goodsAddBatch',
            component: () => import('@/views/sellerCenter/goodsManagement/goodsAddBatch.vue'),
          },
          {
            path: 'audit/:id?',
            name: 'goodsAudit',
            component: () => import('@/views/sellerCenter/goodsManagement/goodsAudit.vue'),
          },
        ]
      },
      {
        path: '/sellerCenter/order',
        component: () => import('@/views/sellerCenter/empty.vue'),
        redirect: '/sellerCenter/order/management',
        meta: {
          title: '销售订单'
        },
        children: [
          {
            path: 'management',
            name: 'orderManagement',
            meta: {
              keepAlive: true
            },
            component: () => import('@/views/sellerCenter/orderManagement/orderList.vue'),
          },
          {
            path: 'orderFragment',
            name: 'OrderFragment',
            component: () => import('@/views/sellerCenter/orderManagement/orderFragment.vue'),
          },
        ]
      },
      {
        path: '/sellerCenter/collage',
        component: () => import('@/views/sellerCenter/empty.vue'),
        redirect: '/sellerCenter/collage/list',
        meta: {
          title: '拼单管理'
        },
        children: [
          {
            path: 'list',
            name: 'collageList',
            meta: {
              keepAlive: true
            },
            component: () => import('@/views/sellerCenter/collage/List.vue'),
          },
          {
            path: 'Add',
            name: 'collageAdd',
            component: () => import('@/views/sellerCenter/collage/Add.vue'),
          },
          {
            path: 'detail',
            name: 'collageDetail',
            component: () => import('@/views/sellerCenter/collage/Detail.vue'),
          }
        ]
      },
      {
        path: '/sellerCenter/contractManagement',
        component: () => import('@/views/sellerCenter/empty.vue'),
        redirect: '/sellerCenter/contractManagement/list',
        meta: {
          title: '销售合同'
        },
        children: [
          {
            path: 'list',
            name: 'contractManagement',
            meta: {
              keepAlive: true
            },
            component: () => import('@/views/sellerCenter/contractManagement/contractList.vue'),
          },
          {
            path: 'contractManagement/detail',
            name: 'contractDetail',
            component: () => import('@/views/sellerCenter/contractManagement/contractDetail.vue'),
          },
          {
            path: 'contractTemplate',
            name: 'contractTemplate',
            component: () => import('@/views/sellerCenter/contractManagement/contractTemplate.vue'),
          },
          {
            path: 'contractTemplate/edit/:id',
            name: 'contractTemplateEdit',
            component: () => import('@/views/sellerCenter/contractManagement/addContractTemp.vue'),
          },
          {
            path: 'contractTemplate/add',
            name: 'contractTemplateAdd',
            component: () => import('@/views/sellerCenter/contractManagement/addContractTemp.vue'),
          },
          {
            path: 'signEdit',
            name: 'SignEdit',
            component: () => import('@/views/sellerCenter/contractManagement/signEdit.vue'),
          }
        ]
      },
      {
        path: '/sellerCenter/logisticsManagement',
        component: () => import('@/views/sellerCenter/empty.vue'),
        redirect: '/sellerCenter/logisticsManagement/list',
        meta: {
          title: '物流管理'
        },
        children: [
          {
            path: 'list',
            name: 'logisticsManagementList',
            meta: {
              keepAlive: true
            },
            component: () => import('@/views/sellerCenter/LogisticsManagement/LogisticsList.vue'),
          },
          {
            path: 'details',
            name: 'logisticsManagementDetails',
            component: () => import('@/views/sellerCenter/LogisticsManagement/details.vue'),
          }
        ]
      },
      {
        path: '/sellerCenter/warehouse',
        component: () => import('@/views/sellerCenter/empty.vue'),
        redirect: '/sellerCenter/warehouse/AddressList',
        meta: {
          title: '提货地管理',
          isHide:false
        },
        children: [
          {
            path: 'AddressList',
            name: 'warehouseAddressList',
            meta: {
              keepAlive: true
            },
            component: () => import('@/views/sellerCenter/warehouse/AddressList.vue'),
          },
          {
            path: 'AddAddress',
            name: 'warehouseAddAddress',
            component: () => import('@/views/sellerCenter/warehouse/AddAddress.vue'),
          },
        ]
      },
      {
        path: '/sellerCenter/shipperManagement',
        component: () => import('@/views/sellerCenter/empty.vue'),
        redirect: '/sellerCenter/shipperManagement/list',
        meta: {
          title: '送货人管理',
          isHide:false
        },
        children: [
          {
            path: 'list',
            name: 'shipperManagementList',
            meta: {
              keepAlive: true
            },
            component: () => import('@/views/sellerCenter/shipperManagement/shipperList.vue'),
          },
          {
            path: 'add',
            name: 'shipperManagementAdd',
            component: () => import('@/views/sellerCenter/shipperManagement/add.vue'),
          },
          {
            path: 'edit/:id',
            name: 'shipperManagementEdit',
            component: () => import('@/views/sellerCenter/shipperManagement/add.vue'),
          },
        ]
      },
      // {
      //   path: '/sellerCenter/store',
      //   component: () => import('@/views/sellerCenter/empty.vue'),
      //   redirect: '/sellerCenter/store/list',
      //   meta: {
      //     title: '店铺管理'
      //   },
      //   children: [
      //     {
      //       path: 'list',
      //       name: 'storeManagementList',
      //       component: () => import('@/views/sellerCenter/storeManagement/list.vue'),
      //     },
      //     {
      //       path: 'add',
      //       name: 'storeManagementAdd',
      //       component: () => import('@/views/sellerCenter/storeManagement/add.vue'),
      //     },
      //   ]
      // },
      // {
      //   path: '/sellerCenter/bankcard',
      //   component: () => import('@/views/sellerCenter/empty.vue'),
      //   meta: {
      //     title: '收款账户'
      //   },
      //   children: [
      //     {
      //       path: '',
      //       name: 'bankCard',
      //       component: () => import('@/views/sellerCenter/bankCard/index.vue'),
      //     },
      //   ]
      // },
    ]
  },
  {
    path: '/deliveryOrder',
    name: 'DeliveryOrder',
    component: () => import('@/views/sellerCenter/contractManagement/DeliveryOrder.vue'),
    meta: {
      isShowHeader: true,
      title:'易缆网-配送单'
    }
  }
]