export default [
  {
    path: '/buyerCenter',
    redirect: '/buyerCenter/order-management/list',
    component: () => import('@/views/buyerBackstage/index.vue'),
    meta: {
      identity: 1
    },
    children: [
      {
        path: '/buyerCenter/order-management',
        redirect: '/buyerCenter/order-management/list',
        component: () => import('@/views/buyerBackstage/empty.vue'),
        meta: { title: '采购订单' },
        children: [
          {
            path: 'order',
            name: 'Order',
            component: () => import('@/views/order/Preview.vue'),
          },
          {
            path: 'list',
            name: 'List',
            meta: {
              keepAlive: true
            },
            component: () => import('@/views/buyerBackstage/order-management/index.vue'),
          },
          {
            path: 'fullPaymentSign',
            name: 'FullPaymentSign',
            component: () => import('@/views/buyerBackstage/order-management/fullPaymentSign.vue'),
          },
          {
            path:'detail',
            name: 'detail',
            component: () => import('@/views/buyerBackstage/order-management/detail.vue'),
          },
          {
            path: 'voucherPage/:id/:amount',
            name: 'VoucherPage',
            component: () => import('@/views/buyerBackstage/order-management/voucherPage.vue'),
          },
          {
            path: 'delivery/:receiverId/:addressId',
            name: 'delivery',
            component: () => import('@/views/buyerBackstage/order-management/delivery.vue'),
          },
          {
            path: 'shipDetails',
            name: 'ShipDetails',
            component: () => import('@/views/buyerBackstage/order-management/shipDetails.vue'),
          },
          {
            path: 'evaluate',
            name: 'Evaluate',
            component: () => import('@/views/buyerBackstage/order-management/components/evaluate.vue'),
          },
          {
            path: 'onlinePay',
            name: 'OnlinePay',
            component: () => import('@/views/buyerBackstage/order-management/onlinePay.vue'),
          }
        ]
      },
      {
        path: '/buyerCenter/contractManage',
        name: 'ContractManage',
        redirect: '/buyerCenter/contractManage/contractList',
        component: () => import('@/views/buyerBackstage/empty.vue'),
        meta: { title: '采购合同' },
        children: [
          {
            path: 'contractList',
            name: 'ContractList',
            meta: {
              keepAlive: true
            },
            component: () => import('@/views/buyerBackstage/contract-management/index.vue'),
          },
          {
            path: 'tempManagement',
            name: 'TempManagement',
            component: () => import('@/views/buyerBackstage/contract-management/tempManagement.vue'),
          },
          {
            path: 'contractAward',
            name: 'ContractAward',
            component: () => import('@/views/buyerBackstage/contract-management/contractAward.vue'),
          },
          {
            path: 'contractConfirm',
            name: 'ContractConfirm',
            component: () => import('@/views/buyerBackstage/contract-management/contractConfirm.vue'),
          },
          {
            path: 'contractTemp',
            name: 'ContractTemp',
            component: () => import('@/views/buyerBackstage/contract-management/contractTemp.vue'),
          },
          {
            path: 'signResponsibility',
            name: 'SignResponsibility',
            component: () => import('@/views/buyerBackstage/contract-management/signResponsibility.vue'),
          }
        ]
      },
      {
        path: '/buyerCenter/activityRecords',
        name: 'ActivityRecords',
        redirect: '/buyerCenter/activityRecords/list',
        component: () => import('@/views/buyerBackstage/empty.vue'),
        meta: { title: '活动记录' },
        children: [{
          path: 'list',
          name: 'list',
          meta: {
            keepAlive: true
          },
          component: () => import('@/views/buyerBackstage/activity-records/index.vue'),
        }]
      },
      
      {
        path: '/buyerCenter/transactionManagement',
        name: 'TransactionManagement',
        redirect: '/buyerCenter/transactionManagement/addressList',
        component: () => import('@/views/buyerBackstage/empty.vue'),
        meta: { title: '收货地址管理' , isHide:false},
        children: [{
          path: 'addressList',
          name: 'addressList',
          meta: {
            keepAlive: true
          },
          component: () => import('@/views/buyerBackstage/transactionAddress/index.vue'),
        },
        {
          path: 'addressManage',
          name: 'AddressManage',
          component: () => import('@/views/buyerBackstage/transactionAddress/addressManage.vue'),
        }]
      },
      {
        path: '/buyerCenter/pickerManage',
        name: 'PickerManage',
        redirect: '/buyerCenter/pickerManage/pickerList',
        component: () => import('@/views/buyerBackstage/empty.vue'),
        meta: { title: '提货人管理' ,isHide:false },
        children: [{
          path: 'pickerList',
          name: 'pickerList',
          meta: {
            keepAlive: true
          },
          component: () => import('@/views/buyerBackstage/transactionAddress/pickerManage.vue'),
        }, {
          path: 'pickerEdit',
          name: 'PickerEdit',
          component: () => import('@/views/buyerBackstage/transactionAddress/pickerEdit.vue'),
        }]
      },
    ]
  },
  {
    path: '/selfDeliveryOrder',
    name: 'SelfDeliveryOrder',
    component: () => import('@/views/buyerBackstage/order-management/selfDeliveryOrder.vue'),
    meta: {
      isShowHeader: true,
      title:'自提单'
    }
  }
]